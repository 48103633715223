import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axiosBaseURL from "../httpCommon";
import SingletonCardComponent from "./Components/SingletonCardComponent";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    AppBar,
    Box,
    TextField,
    Typography,
    Drawer,
    CssBaseline,
    Toolbar,
    FormControl,
    List,
    Stack,
    Card,
    CardContent,
    CardMedia,
    Skeleton,
} from "@mui/material";

const drawerWidth = 180;

export default function SoftwareCompareScreen() {
    const [softwareOverviewList, setSoftwareOverviewList] = useState([]);
    const [software, setSoftware] = useState([]);
    const [catValue, setCatValue] = useState([]);
    const [category, setCategory] = useState("");
    const [company, setCompany] = useState("");
    const [feature, setFeature] = useState("");
    const [keyword, setKeyword] = useState("");
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const publicUrl =
        process.env.REACT_APP_IS_DEPLOYMENT == "true"
            ? process.env.REACT_APP_PUBLIC_URL
            : process.env.REACT_APP_PUBLIC_URL_TEST;

    //Function to access each software in the list for card
    useEffect(() => {
        const fetchSoftwareOverviews = async () => {
            try {
                const response = await axiosBaseURL.get("/apiv1/all_sw_lst/");
                setSoftwareOverviewList(response.data.message);
            } catch (error) {
                console.error(error);
            }
        };
        fetchSoftwareOverviews();
    }, []);

    //Function to access the data from each software for the card's info
    useEffect(() => {
        const fetchSoftwareOverviewData = async () => {
            try {
                // Create an array of promises for fetching detailed data
                const requests = softwareOverviewList.map((overview) =>
                    axiosBaseURL.get(
                        `/apiv1/get_software_overview/${overview.software_id}`
                    )
                );

                // Await all the requests to complete
                const responses = await Promise.all(requests);

                // Extract data from responses and update state
                const softwareData = responses.map(
                    (response) => response.data["software product"]
                );
                setSoftware(softwareData);
            } catch (error) {
                console.error("Error fetching software details:", error);
            }
        };

        fetchSoftwareOverviewData();
    }, [softwareOverviewList]);

    //Function to submit the keyword search field
    const submitSearch = () => {
        console.log(keyword);
        setIsLoading(true);
        setKeyword("");
    };

    //Carousel settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 250,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
        appendDots: (dots) => (
            <div
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
    };

    //Define the drawer component
    const drawer = (
        <div>
            <Toolbar />
            <Typography variant="h6" marginTop={4} sx={{ marginLeft: "15px" }}>
                {" "}
                Advanced Search
            </Typography>
            <Box marginTop={2}>
                <List sx={{ alignContent: "center", textAlign: "center" }}>
                    <FormControl sx={{ width: "93%", marginLeft: "5px" }} size="small">
                        <TextField
                            label="By Category"
                            name="category"
                            id="category"
                            variant="outlined"
                            sx={{ margin: "auto" }}
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            select
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {catValue.map((option) => (
                                <option key={option} value={option.category}>
                                    <div dangerouslySetInnerHTML={{ __html: option.category }} />
                                </option>
                            ))}
                        </TextField>
                    </FormControl>
                </List>
            </Box>
            <Box marginTop={2}>
                <List sx={{ alignContent: "center", textAlign: "center" }}>
                    <FormControl sx={{ width: "93%", marginLeft: "5px" }} size="small">
                        <TextField
                            label="By Company"
                            name="company"
                            id="company"
                            variant="outlined"
                            sx={{ margin: "auto" }}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            select
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {catValue.map((option) => (
                                <option key={option} value={option.category}>
                                    <div dangerouslySetInnerHTML={{ __html: option.category }} />
                                </option>
                            ))}
                        </TextField>
                    </FormControl>
                </List>
            </Box>
            <Box marginTop={2}>
                <List sx={{ alignContent: "center", textAlign: "center" }}>
                    <FormControl sx={{ width: "93%", marginLeft: "5px" }} size="small">
                        <TextField
                            label="By Feature"
                            name="feature"
                            id="feature"
                            variant="outlined"
                            sx={{ margin: "auto" }}
                            value={feature}
                            onChange={(e) => setFeature(e.target.value)}
                            select
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {catValue.map((option) => (
                                <option key={option} value={option.category}>
                                    <div dangerouslySetInnerHTML={{ __html: option.category }} />
                                </option>
                            ))}
                        </TextField>
                    </FormControl>
                </List>
            </Box>
            <Box
                marginTop={2}
                marginLeft={2}
                display={"flex"}
                alignItems={"center"}
                paddingRight={3}
            >
                <FilterAltIcon size="xl" />
                <Typography sx={{ fontSize: "20px", marginLeft: 1 }}>
                    Filters
                </Typography>
            </Box>
        </div>
    );

    //Handle drawer open and close functionality
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
        /*setCategory("")
            setCompany("")
            setFeature("")*/
    };

    // ******ADVANCED SEARCH API CALLS******
    // Get the categories for the select field
    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await axiosBaseURL
                    .get("/apiv1/getFeaureCategoryList/")
                    .then((response) => {
                        setCatValue(response.data.category);
                        console.log("API response:", response.data);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            } catch (error) {
                // Handle errors if the request fails
                console.error("Error:", error);
            }
        };
        fetchCategoryData();
    }, []);

    return (
        <>
            <CssBaseline />
            <Drawer
                anchor="right"
                variant="temporary"
                open={open}
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", md: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                anchor="right"
                variant="permanent"
                sx={{
                    display: { xs: "none", md: "block" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
            <Stack spacing={2} sx={{ alignItems: "center", marginTop: 2 }}>
                <Box
                    sx={{
                        width: "75%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <AppBar
                        position="sticky"
                        style={{
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            bottom: 0,
                            top: "auto",
                        }}
                        className="chatQuestionAppBar"
                    >
                        <Toolbar variant="dense">
                            <Stack
                                direction={"row"}
                                spacing={2}
                                alignItems={"center"}
                                style={{
                                    width: "100%",
                                    padding: "5px",
                                    border: "1px solid #E0E0E0",
                                    borderRadius: "25px",
                                    boxShadow: "0px 2px 4px #6C98AD",
                                    backgroundColor: "white",
                                }}
                            >
                                <input
                                    type="text"
                                    value={keyword}
                                    onChange={(event) => setKeyword(event.target.value)}
                                    style={{
                                        borderRadius: "25px",
                                        flex: 1,
                                        border: "none",
                                        outline: "none",
                                        fontSize: "15px",
                                        marginLeft: "5px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                    placeholder="Search by keyword..."
                                />
                                <div className="chatQuestionSendButton">
                                    <SearchIcon
                                        onClick={
                                            !isLoading || !keyword
                                                ? () => submitSearch(keyword)
                                                : null
                                        }
                                        sx={{
                                            fontSize: 25,
                                            color: isLoading || !keyword ? "white" : "gray",
                                            pointerEvents: isLoading ? "none" : "auto",
                                        }}
                                    />
                                </div>
                            </Stack>
                        </Toolbar>
                    </AppBar>
                    <Box
                        marginTop={1}
                        sx={{
                            maxWidth: "100%",
                            display: { xs: "flex", md: "none" },
                            justifyContent: "center",
                        }}
                    >
                        <Typography
                            variant="h6"
                            textAlign={"center"}
                            sx={{
                                fontSize: "14px",
                                "&:hover": { color: "gray", textDecoration: "underline" },
                            }}
                            style={{ cursor: "pointer" }}
                            onClick={toggleDrawer(true)}
                        >
                            Advanced Search
                        </Typography>
                    </Box>
                </Box>
            </Stack>
            <div
                className="slider-container"
                style={{
                    marginTop: "50px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Slider {...settings}>
                    {software.length > 0 ? (
                        software.map((item, index) => (
                            <SingletonCardComponent
                                key={index}
                                softwareList={item}
                            ></SingletonCardComponent>
                        ))
                    ) : (
                        <Card
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexShrink: "0",
                                width: "350px",
                                height: "550px",
                                borderRadius: "10px",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                                alignItems: "center",
                                backgroundColor: "#F7F8F9",
                                overflowY: "scroll",
                            }}
                        >
                            <CardMedia
                                component="div"
                                sx={{
                                    width: 300,
                                    height: 200,
                                    padding: 1,
                                    margin: 3,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Skeleton variant="rectangular" width={300} height={200} />
                            </CardMedia>
                            <Box
                                sx={{
                                    paddingLeft: 2,
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                }}
                            >
                                <Typography variant={"h4"}>
                                    <Skeleton width="60%" />
                                </Typography>
                                <CardContent sx={{ flex: "1 0 auto" }}>
                                    <Box marginTop={2}>
                                        <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                                            <Skeleton width="30%" />
                                        </Typography>
                                        <Box>
                                            <Skeleton width="100%" height={20} />
                                            <Skeleton width="100%" height={20} />
                                            <Skeleton width="100%" height={20} />
                                        </Box>
                                    </Box>
                                    <Box marginTop={2}>
                                        <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                                            <Skeleton width="30%" />
                                        </Typography>
                                        <Box>
                                            <Skeleton width="100%" height={20} />
                                            <Skeleton width="100%" height={20} />
                                            <Skeleton width="100%" height={20} />
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Card>
                    )}
                </Slider>
            </div>
        </>
    );
}
