import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Alert, Divider, FormControl, Stack, TextField } from "@mui/material";
import AlertModal from "../../Globals/AlertModal";
import axiosBaseURL from "../../../httpCommon";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: '15px',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.3)', // Increased blur radius for a more blurry shadow
    p: 4,
};

export default function PasswordUpdateModal({ openModal, handleCloseModal, setIsPreventClose }) {
    const [password, setPassword] = React.useState({
        current_password: '',
        new_password: '',
        repeat_password: '',
    });

    const [openAlertModal, setOpenAlertModal] = React.useState(false);
    const handleOpenAlertModal = () => setOpenAlertModal(true);
    const handleCloseAlertModal = () => setOpenAlertModal(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertStatus, setAlertStatus] = React.useState("");

    const [isPreventSubmit, setIsPreventSubmit] = React.useState(true);

    const handleChange = (name, value) => {
        let validationMessage = "";
        let validationColor = "";

        if (name === "new_password") {
            const passwordValidation = isValidPassword(value);
            validationMessage = passwordValidation.message;
            validationColor = passwordValidation.color;
        } else if (name === "repeat_password") {
            validationMessage = value === password.new_password ? "Passwords match!" : "Passwords do not match";
            validationColor = value === password.new_password ? "green" : "red";
        }

        setPassword(prevState => ({
            ...prevState,
            [name]: value,
            [`${name}ValidationMessage`]: validationMessage,
            [`${name}ValidationColor`]: validationColor
        }));

        setIsPreventSubmit(
            password.new_password === '' ||
            password.repeat_password === '' ||
            password.new_password !== password.repeat_password ||
            password.new_password.length < 8
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(password);
        try {
            const response = await axiosBaseURL.post("/auth/update-password/", {
                current_password: password.current_password,
                new_password: password.new_password
            }).then((response) => {
                console.log("API response:", response.data);
                setIsPreventClose(false);
                setAlertMessage("Password was updated successfully.");
                setAlertStatus("success");
                handleCloseModal();
                handleOpenAlertModal();
            }).catch((error) => {
                console.error("Error:", error);
                setAlertMessage("There was an error updating your password. Please try again.");
                setAlertStatus("error");
                handleOpenAlertModal();
            });
        } catch (error) {
            console.error("Error:", error);
            setAlertMessage("There was an error on our end. Please try again.");
            setAlertStatus("error");
            handleOpenAlertModal();
        }
    };

    const isValidPassword = (password) => {
        if (password.length < 8) {
            return { message: "Password must be at least 8 characters long.", color: "red" };
        }
        return { message: "Password is valid.", color: "green" };
    };

    return (
        <div>
            <AlertModal openModal={openAlertModal} handleCloseModal={handleCloseAlertModal} status={alertStatus} message={alertMessage} />
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h5">
                        Update Password
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            <FormControl fullWidth sx={{ flex: '1 1 100%' }} margin="dense">
                                <TextField
                                    id="current_password"
                                    name="current_password"
                                    label="Current Password"
                                    type="password"
                                    variant="outlined"
                                    size="small"
                                    value={password.current_password}
                                    onChange={(e) => handleChange('current_password', e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ flex: '1 1 100%' }} margin="dense">
                                <TextField
                                    id="new_password"
                                    name="new_password"
                                    label="New Password"
                                    type="password"
                                    variant="outlined"
                                    size="small"
                                    value={password.new_password}
                                    onChange={(e) => handleChange('new_password', e.target.value)}
                                    helperText={password.new_passwordValidationMessage}
                                    FormHelperTextProps={{ style: { color: password.new_passwordValidationColor || 'inherit' } }}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ flex: '1 1 100%' }} margin="dense">
                                <TextField
                                    id="repeat_password"
                                    name="repeat_password"
                                    label="Repeat Password"
                                    type="password"
                                    variant="outlined"
                                    size="small"
                                    value={password.repeat_password}
                                    onChange={(e) => handleChange('repeat_password', e.target.value)}
                                    helperText={password.repeat_passwordValidationMessage}
                                    FormHelperTextProps={{ style: { color: password.repeat_passwordValidationColor || 'inherit' } }}
                                />
                            </FormControl>
                        </Box>
                        <Stack direction="row" spacing={2} justifyContent="right" alignItems={"right"} sx={{ marginTop: 2 }}>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                sx={{ mt: 2 }}
                                disabled={isPreventSubmit}
                            >
                                Change
                            </Button>
                            <Button onClick={handleCloseModal} variant="contained" color="error" sx={{ mt: 2, padding: 1 }}>
                                Cancel
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}