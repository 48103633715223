import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import ConstructionIcon from "@mui/icons-material/Construction";
import CompareIcon from "@mui/icons-material/Compare";
import Avatar from "@mui/material/Avatar";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";

import axiosBaseURL from "../../httpCommon";

export default function AccountComponent() {
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        axiosBaseURL
            .get("/auth/check-permission/")
            .then((response) => {
                console.log("API Response:", response.data);
                if (
                    response.data["Groups"].includes("admin") ||
                    response.data["Groups"].includes("SME")
                ) {
                    setIsEdit(true);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    return (
        <Box marginTop={2}>
            <Container maxWidth="fixed">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div
                            style={{
                                backgroundColor: "white",
                                height: "87vh",
                                overflow: "auto",
                                borderRadius: "5px",
                                padding: "10px",
                            }}
                        >
                            <List
                                sx={{
                                    width: "100%",
                                    maxWidth: 360,
                                    bgcolor: "background.paper",
                                }}
                            >
                                {isEdit && (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <AddIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <Link to="/account/addSoftware">
                                            <ListItemText
                                                primary="Add Software"
                                                secondary="Add a new software product."
                                            />
                                        </Link>
                                    </ListItem>
                                )}
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <ConstructionIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Link to="/account/manageSoftware">
                                        <ListItemText
                                            primary="Manage Software"
                                            secondary="Modify/Delete existing software products"
                                        />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CompareIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Link to="/account/compareSoftware">
                                        <ListItemText
                                            primary="Compare Software"
                                            secondary="Compare software product's features, pricing, etc."
                                        />
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Avatar />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <Link to="/account/profile">
                                        <ListItemText
                                            primary="Profile"
                                            secondary="Manage your profile"
                                        />
                                    </Link>
                                </ListItem>
                            </List>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
