import {
    Box,
    Button,
    Modal,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axiosBaseURL from "../../../httpCommon";
import AlertModal from "../../Globals/AlertModal";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

export default function AddFeatureModel({
    open,
    setOpen,
    handleOpen,
    categories,
    set_all_features,
}) {
    const [catValue, setCatValue] = useState("");
    const [feat, setFeat] = useState("");
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [alertModal, setAlertModal] = useState({
        status: "",
        open: false,
        message: "",
    });
    const handleClose = () => {
        setOpen(false);
        setCatValue("");
        setFeat("");
    };

    const submitFeature = async (event) => {
        event.preventDefault();
        const value = {
            feature_name: feat,
            category: catValue,
        };

        try {
            await axiosBaseURL
                .post("/apiv1/add_feature/", value)
                .then((response) => {
                    set_all_features(response.data.message);
                    setAlertModal({
                        status: "success",
                        open: true,
                        message: "Form Submitted!",
                    });
                })
                .catch((error) => {
                    console.error("Error:", error);
                    handleShow();
                    setAlertModal({
                        status: "error",
                        open: true,
                        message: "There's an error!",
                    });
                });
            setOpen(false);
            setCatValue("");
            setFeat("");
        } catch (error) {
            // Handle errors if the request fails
            console.error("Error:", error);
        }
        try {
            axiosBaseURL
                .get("/apiv1/getFeatureList/")
                .then((features) => {
                    set_all_features(features.data.message);
                    console.log("API response:", features.data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } catch (error) {
            // Handle errors if the request fails
            console.error("Error:", error);
        }
    };

    return (
        <>
            <AlertModal alertModal={alertModal} />
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
                <Box sx={style}>
                    <Stack spacing={2} direction={"column"}>
                        <Typography id="modal-title" variant="h6" component="h1">
                            Add New Feature
                        </Typography>
                        <TextField
                            label="Feature Name"
                            name="feature"
                            id="feature"
                            variant="outlined"
                            value={feat}
                            onChange={(e) => setFeat(e.target.value)}
                            sx={{ margin: "auto" }}
                            fullWidth
                        />
                        {categories && (
                            <TextField
                                helperText="Select Category"
                                name="category"
                                id="category"
                                variant="outlined"
                                sx={{ margin: "auto" }}
                                value={catValue}
                                onChange={(e) => setCatValue(e.target.value)}
                                select
                                SelectProps={{
                                    native: true,
                                }}
                                fullWidth
                            >
                                {categories.map((option) => (
                                    <option key={option} value={option.category}>
                                        {option.category}
                                    </option>
                                ))}
                            </TextField>
                        )}
                        <Stack spacing={2} direction={"row"}>
                            <Button
                                onClick={submitFeature}
                                variant="outlined"
                                color="success"
                                sx={{ color: "green" }}
                            >
                                Add
                            </Button>
                            <Button
                                onClick={handleClose}
                                variant="outlined"
                                color="error"
                                sx={{ color: "red" }}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}
