import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./Screens/HomeScreen";
import LoginScreen from "./Screens/Accounts/LoginScreen";
import Account from "./Screens/Accounts/AccountScreen.js";
import RegisterScreen from "./Screens/Accounts/RegisterScreen";
import HeaderComponent from "./Screens/Components/HeaderComponent";
import AddSoftware from "./Screens/AddSoftware/AddSoftwareScreen";
import ManageSoftware from "./Screens/Accounts/ManageSoftware.js";
import SWOTScreen from "./Screens/SWOTScreen";
import FooterComponent from "./Screens/Components/FooterComponent";
import LoadingScreen from "./Screens/Globals/LoadingScreen.js";
import SoftwareCompareScreen from "./Screens/SoftwareCompareScreen";
import ErrorScreen from "./Screens/Globals/ErrorScreen.js";
import axiosBaseURL from "./httpCommon";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SoftwareListSidebar from "./Screens/Components/SoftwareListSidebar";
import ProfileScreen from "./Screens/Accounts/ProfileScreen.js";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";

const theme = createTheme();
const drawerWidth = 240;

const pageTitleMap = {
  "/": "ChatAlloy",
  "/login": "Login",
  "/register": "Register",
  "/account": "Account",
  "/account/addSoftware": "Add Software",
  "/account/manageSoftware": "Manage Software",
  "/account/manageSoftware/swot/:id": "SWOT Analysis",
};

const getPageTitle = (path) => {
  if (path.match(/^\/account\/manageSoftware\/swot\/\d+$/)) {
    return "SWOT Analysis";
  }
  return pageTitleMap[path] || "ChatAlloy";
};

const PageTitle = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const title = getPageTitle(currentPath);

  return (
    <Typography variant="p" component="div" sx={{ flexGrow: 1 }}>
      <b>{title}</b>
    </Typography>
  );
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    axiosBaseURL
      .get("/auth/check-permission/")
      .then((response) => {
        console.log("API Response:", response.data);
        if (
          response.data["Groups"].includes("admin") ||
          response.data["Groups"].includes("SME")
        ) {
          setIsEdit(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    const validateToken = async () => {
      try {
        await axiosBaseURL.post("/auth/validate_token/");
        setIsLoggedIn(true);
      } catch (error) {
        setIsLoggedIn(false);
        console.log("Error:", error);
      }
    };
    validateToken();
  }, []);

  if (isLoggedIn === null) {
    return <LoadingScreen />;
  }

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  // const handleDrawerTransitionEnd = () => {
  //   setIsClosing(false);
  // };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div>{isLoggedIn && <HeaderComponent />}</div>
        <div>
          <Box sx={{ display: "flex" }}>
            {isLoggedIn && (
              <SoftwareListSidebar
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                handleDrawerClose={handleDrawerClose}
              />
            )}
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                width: { sm: isLoggedIn ? `calc(100% - ${drawerWidth}px)` : 0 },
                ml: { sm: isLoggedIn ? `${drawerWidth}px` : 0 },
              }}
            >
              {isLoggedIn && (
                <AppBar
                  position="sticky"
                  sx={{
                    width: "100%",
                  }}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    boxShadow: "none",
                    borderBottom: "1px solid #E0E0E0",
                  }}
                >
                  <Toolbar variant="dense">
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2, width: "50px", display: { sm: "none" } }}
                      onClick={handleDrawerToggle}
                    >
                      <MenuIcon />
                    </IconButton>
                    <PageTitle />
                  </Toolbar>
                </AppBar>
              )}
              <Routes>
                <Route
                  path="/"
                  element={isLoggedIn ? <Home /> : <Navigate to="/login" />}
                />
                <Route
                  path="/login"
                  element={!isLoggedIn ? <LoginScreen /> : <Navigate to="/" />}
                />
                <Route path="/register" element={<RegisterScreen />} />
                <Route
                  path="/account"
                  element={isLoggedIn ? <Account /> : <Navigate to="/login" />}
                />
                {isEdit && (
                  <Route
                    path="/account/addsoftware/:id?"
                    element={
                      isLoggedIn ? <AddSoftware /> : <Navigate to="/login" />
                    }
                  />
                )}
                <Route
                  path="/account/manageSoftware"
                  element={
                    isLoggedIn ? <ManageSoftware /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/account/manageSoftware/swot/:id"
                  element={
                    isLoggedIn ? <SWOTScreen /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/account/profile"
                  element={
                    isLoggedIn ? <ProfileScreen /> : <Navigate to="/login" />
                  }
                />
                <Route
                  path="/account/compareSoftware"
                  element={
                    isLoggedIn ? (
                      <SoftwareCompareScreen />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="*"
                  element={
                    isLoggedIn ? <ErrorScreen /> : <Navigate to="/login" />
                  }
                />
              </Routes>
              <FooterComponent />
            </Box>
          </Box>
        </div>
      </Router>
    </ThemeProvider>
  );
}
export default App;
