import React from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SWOTItemComponent({ softwareList }) {
    const [showFullText, setShowFullText] = React.useState(false);
    const navigate = useNavigate();
    const publicUrl =
        process.env.REACT_APP_IS_DEPLOYMENT == "true"
            ? process.env.REACT_APP_PUBLIC_URL
            : process.env.REACT_APP_PUBLIC_URL_TEST;

    const handleReadMoreClick = () => {
        setShowFullText(!showFullText);
    };

    const handleOpenSoftware = (id) => {
        navigate("/account/manageSoftware/swot/" + id);
    };

    const truncatedDescription = softwareList.description
        ? softwareList.description.split(" ").slice(0, 30).join(" ") + "..."
        : "";

    return (
        <>
            <Card sx={{ display: "flex" }}>
                <CardMedia
                    component="img"
                    sx={{ width: 150, height: 150, margin: 1 }}
                    image={publicUrl + "/" + softwareList.logo_path}
                    alt="Live from space album cover"
                    onClick={() => handleOpenSoftware(softwareList.software_id)}
                    style={{ cursor: "pointer", objectFit: "contain" }}
                />
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography component="div" variant="h5">
                            <div
                                dangerouslySetInnerHTML={{ __html: softwareList.name }}
                                onClick={() => handleOpenSoftware(softwareList.software_id)}
                                style={{ cursor: "pointer" }}
                            />
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                        >
                            {showFullText ? (
                                <div
                                    dangerouslySetInnerHTML={{ __html: softwareList.description }}
                                />
                            ) : (
                                <div>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: truncatedDescription }}
                                    />
                                    <a href="#" onClick={handleReadMoreClick}>
                                        Read more
                                    </a>
                                </div>
                            )}
                        </Typography>
                    </CardContent>
                </Box>
            </Card>
        </>
    );
}
