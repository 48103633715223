import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import { Stack } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";

export default function ChatResponseCard({ answers, questions, handleDelete }) {
    const [openNotify, setOpenNotify] = React.useState(false);
    const [isSpeakLoading, setIsSpeakLoading] = React.useState(false);
    const [isSpeaking, setIsSpeaking] = React.useState(false);
    const [isPaused, setIsPaused] = React.useState(false);
    const [audio, setAudio] = React.useState(new Audio());
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [notifyMessage, setNotifyMessage] = React.useState("message");

    const handleSpeak = async (answer) => {
        if (isPlaying || typeof answer !== "string" || !answer) {
            console.error("Invalid answer provided");
            return;
        }

        if (isPlaying) {
            audio.pause();
        }

        setIsSpeakLoading(true);

        // Pause and reset the current audio if it's playing
        audio.pause();
        audio.currentTime = 0;

        setIsPlaying(true);

        try {
            const response = await fetch(`https://api.openai.com/v1/audio/speech`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer sk-proj-2cUbL0Bt7rxsDv2mMSTTT3BlbkFJukuy9V7VDkAz27Sm7D2E`,
                },
                body: JSON.stringify({
                    model: "tts-1",
                    input: answer,
                    voice: "onyx",
                }),
            });

            const arrayBuffer = await response.arrayBuffer();
            const audioBlob = new Blob([arrayBuffer], { type: "audio/mp3" });

            const audioUrl = URL.createObjectURL(audioBlob);

            audio.onended = () => {
                setIsPlaying(false);
                setIsSpeaking(false); // Move this line here
            };

            setIsSpeakLoading(false);
            setIsSpeaking(true);
            audio.src = audioUrl;
            await audio.play();
        } catch (error) {
            console.error("Error playing audio:", error);
            isPlaying = false;
            setIsSpeaking(false);
            setIsSpeakLoading(false);
        }
    };

    const handlePause = () => {
        audio.pause();
        setIsPlaying(false);
        setIsSpeaking(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenNotify(false);
    };

    const handleCopy = (answer) => {
        // navigator.clipboard.writeText(answer);
        setNotifyMessage("Copied to clipboard");
        setOpenNotify(true);
    };
    const handleDeleteCall = (index) => {
        handleDelete(index);
    };

    const tools = (answer, index) => (
        <Stack direction="row" spacing={2} sx={{ marginLeft: 1 }}>
            {isSpeakLoading ? (
                <img
                    src={`${process.env.PUBLIC_URL}/static/media/loader.webp`}
                    alt="loader"
                    height="20px"
                    width="auto"
                />
            ) : isSpeaking ? (
                <PauseCircleFilledIcon
                    className="pauseStyle"
                    onClick={() => handlePause(answer)}
                />
            ) : (
                <RecordVoiceOverIcon
                    className="toolsStyle"
                    onClick={() => handleSpeak(answer)}
                />
            )}
            <ContentCopyIcon
                className="toolsStyle"
                onClick={() => handleCopy(answer)}
            />
            <ReplayIcon className="toolsStyle" />
            <DeleteIcon
                className="toolsStyle"
                onClick={() => handleDeleteCall(index)}
            />
        </Stack>
    );

    const notify = (
        <Snackbar
            open={openNotify}
            autoHideDuration={6000}
            onClose={handleClose}
            message="Chat Copied to clipboard"
        />
    );

    

    const card = (question, answer, index) => (
        <React.Fragment>
            <CardHeader
                sx={{ textAlign: "left" }}
                titleTypographyProps={{ variant: "h6" }} // Add this line to make the title smaller
                title={`${question}`}
            />
            <Divider />
            <CardContent>
                <Stack direction="row" spacing={2}>
                    <Typography variant="p" component="div" sx={{ textAlign: "left" }}>
                        {answer}
                    </Typography>
                </Stack>
            </CardContent>
            <CardActions>{tools(answer, index)}</CardActions>
        </React.Fragment>
    );
    return (
        <>
            
                <Box>
                    {answers.map((answer, index) => (
                        <Card
                            key={index} // Added a key prop for list items
                            variant="outlined"
                            sx={{
                                minWidth: 275,
                                margin: "20px",
                                backgroundColor: "#F8FBFE",
                                boxShadow: "0px 2px 4px #6C98AD",
                                border: "none",
                            }}
                        >
                            {card(questions[index], answer, index)}
                        </Card>
                    ))}
                </Box>
            {openNotify && notify}
        </>
    );
}
