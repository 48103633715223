import React from "react";
import { Divider, FormControl, Stack, TextField, Tooltip } from "@mui/material";
import { Cancel, Done } from "@mui/icons-material";

function MarketingComponent({
    key,
    marketing,
    handleSave,
    handleDelete,
    handleChange,
}) {
    const marketingList = [
        {
            id: 1,
            label: "Business to Consumers",
        },
        {
            id: 2,
            label: "Business to Business",
        },
    ];
    return (
        <Stack spacing={2} direction={{ sm: "column", md: "row" }} marginBottom={2}>
            <TextField
                label="Distributor Name"
                name="distribName"
                id="distribName"
                variant="outlined"
                sx={{ margin: "auto" }}
                fullWidth
            />
            <FormControl
                id="select"
                fullWidth
                sx={{
                    margin: "auto",
                    marginTop: { xs: "15px", sm: "auto" },
                }}
            >
                <TextField
                    helperText="Marketing/Sales Type"
                    name="marketType"
                    id="marketType"
                    variant="outlined"
                    defaultValue={marketing[0]}
                    fullWidth
                    select
                    SelectProps={{
                        native: true,
                    }}
                >
                    {marketingList.map((option) => (
                        <option key={option.id} value={option.label}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
            </FormControl>
            <TextField
                label="Contact Number"
                name="contactNumber"
                id="contactNumber"
                variant="outlined"
                sx={{
                    margin: "auto",
                    marginTop: { xs: "15px", sm: "auto" },
                    marginBottom: { xs: "15px", sm: "auto" },
                }}
                fullWidth
            />
            <TextField
                label="Email"
                name="marketEmail"
                id="marketEmail"
                variant="outlined"
                sx={{
                    margin: "auto",
                    marginBottom: { xs: "15px", sm: "auto" },
                }}
                fullWidth
            />
            <TextField
                label="Website"
                name="marketWebsite"
                id="marketWebsite"
                variant="outlined"
                sx={{ margin: "auto" }}
                fullWidth
            />
            <Stack direction="column">
                {!marketing.isSaved && (
                    <>
                        <Tooltip title="Save" placement="right-end">
                            <Done className="doneIcon" onClick={handleSave} />
                        </Tooltip>
                        <Divider orientation="horizontal" flexItem sx={{ my: 0.5 }} />
                    </>
                )}
                <Tooltip title="Delete" placement="right-end">
                    <Cancel className="cancelIcon" onClick={handleDelete} />
                </Tooltip>
            </Stack>
        </Stack>
    );
}

export default MarketingComponent;
