import React, { useEffect, useState } from "react";
import {
    Stack,
    TextField,
    Container,
    Button,
    Box,
    Tooltip,
    Toolbar,
    Divider,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";
import Chip from "@mui/material/Chip";
import axiosBaseURL from "../../httpCommon";
import { useNavigate } from "react-router-dom";
import "./Components/AddSoftware.css";

// Import Components
import PricingComponent from "./Components/Pricing";
import FeaturesComponent from "./Components/Features";
import IntegrationsComponent from "./Components/Integrations";
import MarketingComponent from "./Components/Marketing";
// Importing Icons
import { Delete, UploadFile } from "@mui/icons-material";
import AddFieldButton from "./Components/AddFieldButton";
import { useParams } from "react-router-dom";

const cadType = [
    {
        id: 1,
        label: "Computer Aided Design",
    },
    {
        id: 2,
        label: "Computer Aided Machining",
    },
    {
        id: 3,
        label: "Quality Management Systems",
    },
    {
        id: 4,
        label: "ERP",
    },
    {
        id: 5,
        label: "Miscellaneous",
    },
    {
        id: 6,
        label: "Simulation",
    },
    {
        id: 7,
        label: "Design, Documentation",
    },
    {
        id: 8,
        label: "Quotation",
    },
];

export default function AddSoftwareScreen(props) {
    //  ************************ DEVELOPMENT START *************************
    const navigate = useNavigate();
    const publicUrl =
        process.env.REACT_APP_IS_DEPLOYMENT == "true"
            ? process.env.REACT_APP_PUBLIC_URL
            : process.env.REACT_APP_PUBLIC_URL_TEST;
    const [isEditor, setIsEditor] = useState(false);

    const [imagePathID, setImagePathID] = useState(1);
    const [imageChanged, setImageChanged] = useState(false);
    const [prodName, setProdName] = useState("");
    const [prodType, setProdType] = useState("");
    const [parent, setParent] = useState("");
    const [year, setYear] = useState(dayjs("2024"));
    const [website, setWebsite] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [description, setDescription] = useState("");
    const [strength, setStrength] = useState("");
    const [weakness, setWeakness] = useState("");
    const [opportunities, setOpportunities] = useState("");
    const [threats, setThreats] = useState("");
    const [pricings, setPricings] = useState([
        {
            isSaved: false,
            title: "",
            subscription: "",
            signUp: "",
            frequency: "",
            user: "",
            id: 0,
        },
    ]);
    const [prodFeatures, setProdFeatures] = useState([
        {
            isSaved: false,
            feature_map_id: "",
            benefits: "",
            pricing: "",
            rating: "",
            feature_id: 0,
        },
    ]);
    const [integrations, setIntegrations] = useState([
        {
            isSaved: false,
            id_integration: 0,
            integration_product: "",
            description: "",
            integration_type: "",
            product_id: "",
        },
    ]);
    const [marketings, setMarketings] = useState([
        {
            isSaved: false,
            distName: "",
            type: "",
            contact: "",
            email: "",
            website: "",
            id: 0,
        },
    ]);

    const addPricing = () => {
        setPricings([
            ...pricings,
            {
                isSaved: false,
                title: "",
                subscription: "",
                signUp: "",
                frequency: "",
                user: "",
                id: 0,
            },
        ]);
    };

    const handleSavePricing = (index) => {
        setPricings((prevPricings) => {
            const newPricings = [...prevPricings];
            newPricings[index].isSaved = true;
            return newPricings;
        });
    };

    const handleDeletePricing = (index) => {
        setPricings((prevPricings) => prevPricings.filter((_, i) => i !== index));
    };
    const handleChangePricing = (index, field, value) => {
        setPricings((prevPricings) => {
            const newPricings = [...prevPricings];
            newPricings[index][field] = value;
            newPricings[index].isSaved = false;
            return newPricings;
        });
    };

    const addProdFeature = () => {
        setProdFeatures([
            ...prodFeatures,
            {
                isSaved: false,
                feature_map_id: "",
                benefits: "",
                pricing: 0,
                rating: "",
                feature_id: 0,
            },
        ]);
    };

    const handleSaveProdFeature = (index) => {
        setProdFeatures((prevProdFeature) => {
            const newProdFeature = [...prevProdFeature];
            newProdFeature[index].isSaved = true;
            return newProdFeature;
        });
    };
    const handleDeleteProdFeature = (index) => {
        setProdFeatures((prevProdFeature) =>
            prevProdFeature.filter((_, i) => i !== index)
        );
    };
    const handleChangeProdFeature = (index, field, value) => {
        setProdFeatures((prevProdFeature) => {
            const newProdFeature = [...prevProdFeature];
            newProdFeature[index][field] = value;
            newProdFeature[index].isSaved = false;
            return newProdFeature;
        });
    };

    const addIntegration = () => {
        setIntegrations([
            ...integrations,
            {
                isSaved: false,
                id_integration: 0,
                integration_product: "",
                description: "",
                integration_type: "",
                product_id: "",
            },
        ]);
    };
    const handleSaveIntegration = (index) => {
        setIntegrations((prevIntegration) => {
            const newIntegration = [...prevIntegration];
            newIntegration[index].isSaved = true;
            return newIntegration;
        });
    };

    const handleDeleteIntegration = (index) => {
        setIntegrations((prevIntegration) =>
            prevIntegration.filter((_, i) => i !== index)
        );
    };
    const handleChangeIntegration = (index, field, value) => {
        setIntegrations((prevIntegration) => {
            const newIntegration = [...prevIntegration];
            newIntegration[index][field] = value;
            newIntegration[index].isSaved = false;
            return newIntegration;
        });
    };

    const addMarketing = () => {
        setMarketings([
            ...marketings,
            {
                isSaved: false,
                distName: "",
                type: "",
                contact: "",
                email: "",
                website: "",
                id: 0,
            },
        ]);
    };
    const handleSaveMarketings = (index) => {
        setMarketings((prevMarketing) => {
            const newMarketing = [...prevMarketing];
            newMarketing[index].isSaved = true;
            return newMarketing;
        });
    };

    const handleDeleteMarketings = (index) => {
        setMarketings((prevMarketing) =>
            prevMarketing.filter((_, i) => i !== index)
        );
    };
    const handleChangeMarketings = (index, field, value) => {
        setMarketings((prevMarketing) => {
            const newMarketing = [...prevMarketing];
            newMarketing[index][field] = value;
            newMarketing[index].isSaved = false;
            return newMarketing;
        });
    };
    // As an Edit form. If the new is true, then it is an edit form.
    const handlePopulate = async (id) => {
        try {
            const response = await axiosBaseURL.get(
                `/apiv1/get_software_overview/${id}`
            );
            const data = response.data["software product"];
            const features = data["features"] ? data["features"] : [];
            const pricing = data["pricing"] ? data["pricing"] : [];
            const {
                prodName,
                prodType,
                year,
                logo,
                website,
                linkedin,
                description,
                strength,
                weakness,
                opportunities,
                threats,
                logo_id,
            } = data;

            prodName && setProdName(prodName);
            prodType && setProdType(prodType);
            year && setYear(dayjs(year));
            logo &&
                setImage({
                    preview: `${publicUrl}/${logo}`,
                    raw: logo,
                });
            (await logo_id) && setImagePathID(logo_id);
            website && setWebsite(website);
            linkedin && setLinkedin(linkedin);
            description && setDescription(description);
            strength && setStrength(strength);
            weakness && setWeakness(weakness);
            opportunities && setOpportunities(opportunities);
            threats && setThreats(threats);

            if (pricing.length) {
                setPricings(pricing);
            }

            if (features.length) {
                setProdFeatures(features);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            setIsEditor(true);
            handlePopulate(id);
        }
    }, [id]);
    const handleUpdate = async () => {
        const value = {};
        value.id = id;
        value.prodName = prodName;
        value.prodType = prodType;
        value.prodParent = parent;
        value.date = year.year();
        value.website = website;
        value.linkedin = linkedin;
        value.description = description;
        value.strength = strength;
        value.weakness = weakness;
        value.opportunities = opportunities;
        value.threats = threats;
        value.path = imagePathID;
        console.log("image:", image);
        if (image.raw && imageChanged) {
            try {
                const formData = new FormData();
                formData.append("path", image.raw);

                const response = await axiosBaseURL.post(
                    "/apiv1/file_upload/",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                setImagePathID(response.data.path);
                console.log("Success Image upload!!", imagePathID, response.data.path);
                value.path = response.data.path;
            } catch (error) {
                console.error("API error:", error);
            }
        }
        value.pricing = [];
        pricings.forEach((element) => {
            value.pricing.push({
                title: element.title,
                signUpFee: element.signUp,
                subCost: element.subscription,
                priceFrequency: element.frequency,
                users: element.user,
                id: element.id,
            });
        });

        value.features = [];
        prodFeatures.forEach((element) => {
            console.log("element:", element);
            value.features.push({
                feature_map_id: element.feature_map_id,
                benefits: element.benefits,
                pricing: element.pricing,
                rating: element.rating,
                id: element.feature_id,
            });
        });

        value.integrations = [];
        integrations.forEach((element) => {
            value.integrations.push({
                id_integration: element.id_integration,
                integration_product: element.integration_product,
                description: element.description,
                integration_type: element.integration_type,
                product_id: element.product_id,
            });
        });

        value.marketing = [];
        marketings.forEach((element) => {
            value.marketing.push({
                distribName: element.distName,
                marketType: element.type,
                contactNumber: element.contact,
                marketEmail: element.email,
                marketWebsite: element.website,
                id: element.id,
            });
        });
        console.log("value:", value);
        try {
            axiosBaseURL
                .post(`/apiv1/editSoftware/${id}/`, value)
                .then((response) => {
                    console.log("API Main response:", response.data);
                    navigate("/account/manageSoftware/swot/" + id);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } catch (error) {
            // Handle errors if the request fails
            console.error("Error:", error);
        }
    };
    // ************************* DEVELOPMENT END *************************

    const [featuresList, setFeaturesList] = useState([
        { id: Date.now().toString(10) },
    ]);
    const [integrationsList, setIntegrationsList] = useState([
        { id: Date.now().toString(10) },
    ]);
    const [priceList, setPriceList] = useState([
        { id: Date.now().toString(10), title: 0 },
    ]);
    const [marketingList, setMarketingList] = useState([
        { id: Date.now().toString(10) },
    ]);

    const [titles, setTitles] = useState([]);
    const [parents, setParents] = useState([
        { software_id: 1, name: "No parent" },
    ]);
    const [image, setImage] = useState({
        preview: "",
        raw: "",
    });

    const [softwareCategories, setSoftwareCategories] = useState([]);
    const [allFeaturesList, setAllFeaturesList] = useState([]);

    useEffect(() => {
        try {
            axiosBaseURL
                .get("/apiv1/getFeatureList/")
                .then((response) => {
                    setAllFeaturesList(response.data.message);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } catch (error) {
            // Handle errors if the request fails
            console.error("Error:", error);
        }
    }, [0]);

    useEffect(() => {
        try {
            axiosBaseURL
                .get("/apiv1/getFeaureCategoryList/")
                .then((response) => {
                    setSoftwareCategories(response.data.category);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } catch (error) {
            // Handle errors if the request fails
            console.error("Error:", error);
        }
    }, [0]);

    useEffect(() => {
        const fetchSoftwareData = async () => {
            try {
                await axiosBaseURL
                    .get("/apiv1/getSoftwareList/")
                    .then((response) => {
                        setParents([...parents, ...response.data.message]);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            } catch (error) {
                // Handle errors if the request fails
                console.error("Error:", error);
            }
        };
        fetchSoftwareData();
    }, []);

    //Functions Dealing with Submit and Reset
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const value = {};

        value.prodName = prodName;
        value.prodType = prodType;
        value.prodParent = parent;
        value.date = year.year();
        value.website = website;
        value.linkedin = linkedin;
        value.description = description;
        value.strength = strength;
        value.weakness = weakness;
        value.opportunities = opportunities;
        value.threats = threats;
        value.path = 1;
        console.log("image:", image);
        if (image.raw) {
            try {
                const formData = new FormData();
                formData.append("path", image.raw);

                const response = await axiosBaseURL.post(
                    "/apiv1/file_upload/",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                setImagePathID(response.data.path);
                console.log("Success Image upload!!", imagePathID, response.data.path);
                value.path = response.data.path;
            } catch (error) {
                console.error("API error:", error);
            }
        }
        value.pricing = [];
        pricings.forEach((element) => {
            value.pricing.push({
                title: element.title,
                signUpFee: element.signUp,
                subCost: element.subscription,
                priceFrequency: element.frequency,
                users: element.user,
            });
        });

        value.features = [];
        prodFeatures.forEach((element) => {
            value.features.push({
                feature_map_id: element.feature_map_id,
                benefits: element.benefits,
                pricing: element.pricing,
                rating: element.rating,
            });
        });

        value.integrations = [];
        integrations.forEach((element) => {
            value.integrations.push({
                id_integration: element.id_integration,
                integration_product: element.integration_product,
                description: element.description,
                integration_type: element.integration_type,
                product_id: element.product_id,
            });
        });

        value.marketing = [];
        marketings.forEach((element) => {
            value.marketing.push({
                distribName: element.distName,
                marketType: element.type,
                contactNumber: element.contact,
                marketEmail: element.email,
                marketWebsite: element.website,
            });
        });
        console.log("value:", value);

        // Make an Axios POST request to your API endpoint
        try {
            axiosBaseURL
                .post("/apiv1/addsoftware/", value)
                .then((response) => {
                    let form = document.getElementById("dbForm");
                    form.reset();
                    handleReset();
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } catch (error) {
            // Handle errors if the request fails
            console.error("Error:", error);
        }
    };

    const handleReset = () => {
        setIsEditor(false);
        setImage({
            preview: "",
            raw: "",
        });
        setProdName("");
        setProdType("");
        setParent("");
        setYear(dayjs("2024"));
        setWebsite("");
        setLinkedin("");
        setDescription("");
        setStrength("");
        setWeakness("");
        setOpportunities("");
        setThreats("");
        setPricings([
            {
                isSaved: false,
                title: "",
                subscription: "",
                signUp: "",
                frequency: "",
                user: "",
                id: 0,
            },
        ]);
        setProdFeatures([
            {
                isSaved: false,
                feature_map_id: "",
                benefits: "",
                subType: "",
                rating: "",
                feature_id: 0,
            },
        ]);
        setIntegrations([
            {
                isSaved: false,
                product: "",
                type: "",
                integrations_id: 0,
            },
        ]);
        setMarketings([
            {
                isSaved: false,
                distName: "",
                type: "",
                contact: "",
                email: "",
                website: "",
                id: 0,
            },
        ]);
    };

    //Functions Dealing with Image Upload
    const handleLogoChange = (event) => {
        if (event.target.files.length) {
            setImage({
                preview: URL.createObjectURL(event.target.files[0]),
                raw: event.target.files[0],
            });
            setImageChanged(true);
        }
    };

    // Loading skeleton
    const LoadingSkeleton = () => {
        return (
            <Box sx={{ display: "flex" }}>
                <Skeleton
                    variant="rectangular"
                    width={20}
                    height={150}
                    sx={{ margin: 1 }}
                />
            </Box>
        );
    };
    const ImageComponent = () => {
        return (
            <>
                {image.preview ? (
                    <div className="image-container">
                        <img
                            alt="Company Logo"
                            src={image.preview}
                            height="100px"
                            width="auto"
                        />
                        <div className="overlay-text">
                            <Stack direction="column">
                                <label htmlFor="upload-button" style={{ margin: 0 }}>
                                    <Chip
                                        icon={<UploadFile />}
                                        label="Change"
                                        variant="outlined"
                                        className="overlayButton"
                                    />
                                </label>
                                <label htmlFor="delete-button" style={{ margin: 0 }}>
                                    <Chip
                                        icon={<Delete />}
                                        label="Delete"
                                        variant="outlined"
                                        className="overlayButton"
                                    />
                                </label>
                            </Stack>
                        </div>
                        <input
                            name="delete"
                            type="button"
                            id="delete-button"
                            style={{ display: "none" }}
                            onClick={() => setImage({ preview: "", raw: "" })}
                        />
                        <input
                            name="path"
                            type="file"
                            id="upload-button"
                            style={{ display: "none" }}
                            onChange={handleLogoChange}
                        />
                    </div>
                ) : (
                    <div className="image-container">
                        <img
                            alt="Logo Placeholder"
                            src={`${process.env.REACT_APP_PUBLIC_URL}/images/logo_placeholder.png`}
                            height="150px"
                        />
                        <div className="overlay-text">
                            <label htmlFor="upload-button">
                                <Chip
                                    icon={<UploadFile />}
                                    label="Upload Logo"
                                    variant="outlined"
                                    className="overlayButton"
                                />
                            </label>
                        </div>
                        <input
                            name="path"
                            type="file"
                            id="upload-button"
                            style={{ display: "none" }}
                            onChange={handleLogoChange}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container fixed sx={{ marginTop: 7 }}>
                <form
                    id="dbForm"
                    onSubmit={handleFormSubmit}
                    encType="multipart/form-data"
                >
                    <Stack
                        direction="row"
                        marginBottom={2}
                        spacing={1}
                        alignItems="center"
                    >
                        <ImageComponent />
                        <TextField
                            error={prodName === ""}
                            label="Name*"
                            variant="outlined"
                            name="prodName"
                            id="prodName"
                            value={prodName}
                            onChange={(e) => setProdName(e.target.value)}
                            fullWidth
                        />
                    </Stack>

                    <Stack spacing={2} direction="column">
                        <Stack spacing={2} direction={{ sm: "column", md: "row" }}>
                            <TextField
                                helperText="Type*"
                                variant="outlined"
                                name="prodType"
                                id="prodType"
                                value={prodType ? prodType : ""}
                                error={prodType === ""}
                                sx={{ margin: "auto" }}
                                fullWidth
                                onChange={(e) => setProdType(e.target.value)}
                                select
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="" disabled>
                                    Select a Type
                                </option>
                                {cadType.map((option) => (
                                    <option key={option.id} value={option.label}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                helperText="Parent*"
                                variant="outlined"
                                name="prodParent"
                                id="prodParent"
                                value={parent ? parent : ""}
                                error={parent === ""}
                                sx={{ margin: "auto" }}
                                fullWidth
                                onChange={(e) => setParent(e.target.value)}
                                select
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <option value="" disabled>
                                    Select a parent
                                </option>{" "}
                                {/* Added disabled default value */}
                                {parents.map((option) => (
                                    <option key={option} value={option.software_id}>
                                        {option.name}
                                    </option>
                                ))}
                            </TextField>
                        </Stack>
                        <Stack spacing={2} direction={{ sm: "column", md: "row" }}>
                            <DatePicker
                                views={["year"]}
                                label="Year Est.*"
                                name="date"
                                id="date"
                                value={year}
                                error={year === "" || year === 0}
                                onChange={(newYear) => setYear(newYear)}
                            />
                            <TextField
                                label="Website"
                                variant="outlined"
                                name="website"
                                id="website"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                                sx={{
                                    margin: "auto",
                                    marginBottom: { xs: "15px", sm: "auto" },
                                    marginTop: { xs: "15px", sm: "auto" },
                                }}
                                fullWidth
                            ></TextField>
                            <TextField
                                label="LinkedIn"
                                variant="outlined"
                                name="linkedin"
                                id="linkedin"
                                value={linkedin}
                                onChange={(e) => setLinkedin(e.target.value)}
                                sx={{ margin: "auto" }}
                                fullWidth
                            ></TextField>
                        </Stack>
                        <TextField
                            label="Description"
                            variant="outlined"
                            name="description"
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ margin: "auto" }}
                            fullWidth
                            multiline
                        />
                    </Stack>

                    {/* **************************************** PRICING OPTIONS **************************************** */}
                    <h2>Pricing</h2>
                    {pricings.map((pricing, index) => (
                        <PricingComponent
                            key={index}
                            pricing={pricing}
                            handleSave={() => handleSavePricing(index)}
                            handleDelete={() => handleDeletePricing(index)}
                            handleChange={(field, value) =>
                                handleChangePricing(index, field, value)
                            }
                        />
                    ))}
                    <AddFieldButton addFunction={addPricing} label="Add Pricing" />

                    {/* **************************************** FEATURES **************************************** */}
                    <h2>Features</h2>
                    {prodFeatures.map((feature, index) => (
                        <FeaturesComponent
                            key={index}
                            feature={feature}
                            pricings={pricings}
                            handleSave={() => handleSaveProdFeature(index)}
                            handleDelete={() => handleDeleteProdFeature(index)}
                            handleChange={(field, value) =>
                                handleChangeProdFeature(index, field, value)
                            }
                            all_features={allFeaturesList}
                            set_all_features={setAllFeaturesList}
                            categories={softwareCategories}
                        />
                    ))}
                    <AddFieldButton addFunction={addProdFeature} label="Add Feature" />

                    {/* **************************************** INTEGRATIONS **************************************** */}
                    <h2>Integrations</h2>
                    {integrations.map((integration, index) => (
                        <IntegrationsComponent
                            key={index}
                            integration={integration}
                            handleSave={() => handleSaveIntegration(index)}
                            handleDelete={() => handleDeleteIntegration(index)}
                            handleChange={(field, value) =>
                                handleChangeIntegration(index, field, value)
                            }
                        />
                    ))}
                    <AddFieldButton
                        addFunction={addIntegration}
                        label="Add Integration"
                    />

                    {/* **************************************** MARKETING **************************************** */}
                    <h2>Marketing and Sales Approach</h2>
                    {marketings.map((marketing, index) => (
                        <MarketingComponent
                            key={index}
                            marketing={marketing}
                            handleSave={() => handleSaveMarketings(index)}
                            handleDelete={() => handleDeleteMarketings(index)}
                            handleChange={(field, value) =>
                                handleChangeMarketings(index, field, value)
                            }
                        />
                    ))}
                    <AddFieldButton addFunction={addMarketing} label="Add Marketing" />

                    {/* **************************************** SWOT **************************************** */}
                    <Box className="swotBox">
                        <h2>
                            <Tooltip title="Strength, Weakness, Opportunity, Threats">
                                S.W.O.T.
                            </Tooltip>
                        </h2>
                        <Stack spacing={2} marginBottom={2}>
                            <TextField
                                label="Strengths"
                                variant="outlined"
                                name="strength"
                                id="strength"
                                value={strength}
                                onChange={(e) => setStrength(e.target.value)}
                                sx={{ margin: "auto" }}
                                fullWidth
                                multiline
                            />
                            <TextField
                                label="Weaknesses"
                                variant="outlined"
                                name="weakness"
                                id="weakness"
                                value={weakness}
                                onChange={(e) => setWeakness(e.target.value)}
                                sx={{ margin: "auto" }}
                                fullWidth
                                multiline
                            />
                            <TextField
                                label="Opportunities"
                                variant="outlined"
                                name="opportunities"
                                id="opportunities"
                                value={opportunities}
                                onChange={(e) => setOpportunities(e.target.value)}
                                sx={{ margin: "auto" }}
                                fullWidth
                                multiline
                            />
                            <TextField
                                label="Threats"
                                variant="outlined"
                                name="threats"
                                id="threats"
                                value={threats}
                                onChange={(e) => setThreats(e.target.value)}
                                sx={{ margin: "auto" }}
                                fullWidth
                                multiline
                            />
                        </Stack>
                    </Box>
                    {/* **************************************** CONTROLS **************************************** */}

                    <div
                        style={{
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            bottom: 0,
                            top: "auto",
                            marginTop: "10px",
                        }}
                        className="chatQuestionAppBar"
                    >
                        <Toolbar variant="dense">
                            <Stack
                                direction={"row"}
                                spacing={2}
                                alignItems={"center"}
                                style={{
                                    width: "100%",
                                    padding: "5px",
                                    border: "1px solid #E0E0E0",
                                    borderRadius: "25px",
                                    boxShadow: "0px 2px 4px #6C98AD",
                                    backgroundColor: "white",
                                }}
                            >
                                <Button
                                    // variant="outlined"
                                    // color="primary"
                                    type="reset"
                                    sx={{ width: "25rem", margin: "auto" }}
                                    onClick={handleReset}
                                >
                                    Clear Form
                                </Button>
                                <Divider orientation="vertical" flexItem />
                                {!isEditor ? (
                                    <Button
                                        // variant="outlined"
                                        // color="primary"
                                        type="submit"
                                        sx={{ width: "25rem", margin: "auto" }}
                                    >
                                        Submit
                                    </Button>
                                ) : (
                                    <Button
                                        // variant="outlined"
                                        // color="primary"
                                        onClick={() => handleUpdate()}
                                        sx={{ width: "25rem", margin: "auto" }}
                                    >
                                        Update
                                    </Button>
                                )}
                            </Stack>
                        </Toolbar>
                    </div>
                </form>
            </Container>
        </LocalizationProvider>
    );
}
