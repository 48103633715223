import { Box, Typography } from "@mui/material";
import React from "react";

export default function ErrorScreen() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginRight: 2,
        }}
      >
        <Typography variant="h1" textAlign={"center"}>
          404
        </Typography>
        <Typography variant="h3" textAlign={"center"}>
          {" "}
          The page was not found.
        </Typography>
        <Typography
          variant="h7"
          component="a"
          href="/"
          sx={{
            marginTop: "10px",
            textDecoration: "none",
            textAlign: "center",
            color: "black",
            "&:hover": { color: "purple" },
          }}
        >
          Go Home
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/static/media/page-not-found-error-404-robot.png`}
          alt="404 Error"
          style={{ height: "400px", width: "auto" }}
        />
      </Box>
    </Box>
  );
}
