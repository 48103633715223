import React from "react";
import {
    Typography,
    Card,
    CardContent,
    CardMedia,
    Box,
    Stack,
    Tooltip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { Help } from "@mui/icons-material";

const styles = {
    textDecoration: "none",
    color: "black",
};

export default function SingletonCardComponent({ softwareList }) {
    const publicUrl =
        process.env.REACT_APP_IS_DEPLOYMENT == "true"
            ? process.env.REACT_APP_PUBLIC_URL
            : process.env.REACT_APP_PUBLIC_URL_TEST;

    return (
        <>
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexShrink: "0",
                    width: "270px",
                    height: "950px",
                    borderRadius: "10px",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    alignItems: "center",
                    backgroundColor: "#F7F8F9",
                    overflowY: "scroll",
                }}
            >
                <CardMedia
                    component="img"
                    sx={{
                        width: 300,
                        height: 200,
                        padding: 1,
                        margin: 3,
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                    // Uncomment and modify the below line to use an actual image path
                    image={publicUrl + "/" + softwareList.logo}
                    alt="Live from space album cover"
                    style={{ objectFit: "contain" }}
                />
                <Box
                    sx={{
                        paddingLeft: 2,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <Typography variant={"h4"}>
                        {" "}
                        <a style={styles} href={softwareList.website} target="blank">
                            {softwareList.prodName}
                        </a>
                    </Typography>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                        <Box marginTop={2}>
                            <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                                Features
                            </Typography>
                            {softwareList.features.map((feature, featureIndex) => (
                                <ul
                                    key={featureIndex}
                                    style={{ listStyleType: "none", margin: 0, padding: 0 }}
                                >
                                    <li>
                                        <Typography sx={{ marginTop: 2 }} variant="body1">
                                            <Stack direction="row">
                                                <CheckIcon sx={{ color: "green" }}></CheckIcon>
                                                <>{feature.featName}</>
                                                <Tooltip title={feature.benefits} placement="right">
                                                    <Help
                                                        sx={{
                                                            marginLeft: "5px",
                                                            fontSize: 12,
                                                            color: "grey",
                                                            cursor: "pointer",
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Stack>
                                        </Typography>
                                    </li>
                                </ul>
                            ))}
                        </Box>
                        <Box marginTop={2}>
                            <Typography variant={"h6"} sx={{ fontWeight: "bold" }}>
                                Pricing
                            </Typography>
                            {softwareList.pricing.map((price, priceIndex) => (
                                <ul
                                    key={priceIndex}
                                    style={{ listStyleType: "none", margin: 0, padding: 0 }}
                                >
                                    <li>
                                        <Typography sx={{ fontWeight: "bold" }} variant="body1">
                                            {price.title}
                                        </Typography>
                                        <Typography variant="body1">
                                            Sign-Up Fee: ${price.signUp}
                                        </Typography>
                                        <Typography variant="body1">
                                            Subscription Cost: ${price.subscription}
                                        </Typography>
                                        <Typography variant="body1">
                                            Frequency: {price.frequency}
                                        </Typography>
                                        <Typography variant="body1">
                                            # of Users: {price.user}
                                        </Typography>
                                    </li>
                                </ul>
                            ))}
                        </Box>
                    </CardContent>
                </Box>
            </Card>
        </>
    );
}
