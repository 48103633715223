import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Divider,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import axiosBaseURL from "../../httpCommon";
import { AutoDelete, EditNote } from "@mui/icons-material";
import ManagedUsers from "./Profile/ManagedUsers";
import SoftwareGrid from "./Profile/SoftwareGrid";
import UserInformation from "./Profile/UserInformation";
import EditInformationModal from "./Profile/EditInformationModal";
import DeleteUserModal from "./Profile/DeleteUserModal";
import AlertModal from "../Globals/AlertModal";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
   

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function ProfileScreen() {
    const [softwareState, setSoftwareState] = useState(false);
    const [userState, setUserState] = useState(false);
    const [isPermission, setIsPermission] = useState("user");

    const [openInfoModal, setOpenInfoModal] = React.useState(false);
    const handleOpenInfoModal = () => setOpenInfoModal(true);
    const handleCloseInfoModal = () => {
        if (!window.confirm("Are you sure?")) {
            return;
        }
        setOpenInfoModal(false);
    };

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);
    // Alert Modal
    const [openAlertModal, setOpenAlertModal] = React.useState(false);
    const handleOpenAlertModal = () => setOpenAlertModal(true);
    const handleCloseAlertModal = () => setOpenAlertModal(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertStatus, setAlertStatus] = React.useState("");

    const [user, setUser] = useState();

    const handleSoftware = () => {
        setSoftwareState(true);
        setUserState(false);
    };

    const handleUsers = () => {
        setUserState(true);
        setSoftwareState(false);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        const getUserData = async () => {
            try {
                const response = await axiosBaseURL
                    .post("/auth/user-info/")
                    .then((response) => {
                        setUser(response.data.message);
                        console.log("API response:", user);
                    })
                    .catch((error) => {
                        // Handle errors if the request fails
                        //  Call the Alert Modal
                        setAlertMessage(
                            "There was an error fetching the user data. Please try again."
                        );
                        setAlertStatus("error");
                        handleOpenAlertModal();
                        console.error("Error:", error);
                    });
            } catch (error) {
                // Handle errors if the request fails
                setAlertMessage(
                    "There was an error on our end. Please try again later."
                );
                setAlertStatus("error");
                handleOpenAlertModal();
                console.error("Error:", error);
            }
        };
        getUserData();
    }, []);

    useEffect(() => {
        axiosBaseURL
            .get("/auth/check-permission/")
            .then((response) => {
                console.log(response.data);
                if (response.data["Groups"].includes("admin")) {
                    setIsPermission("admin");
                } else if (response.data["Groups"].includes("SME")) {
                    setIsPermission("SME");
                } else {
                    setIsPermission("user");
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    return (
        <>
            <AlertModal
                openModal={openAlertModal}
                handleCloseModal={handleCloseAlertModal}
                status={alertStatus}
                message={alertMessage}
            />
            <Container
                maxWidth="fixed"
                style={{
                    marginTop: "5%",
                }}
            >
                {user ? (
                    <div>
                        <Card style={{ width: "80%", margin: "0 auto" }}>
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <CardContent>
                                    <Stack
                                        direction={"row"}
                                        spacing={2}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                    >
                                        <Avatar
                                            alt="Remy Sharp"
                                            src="/static/images/profiles/avat1.jpg"
                                            sx={{ width: 70, height: 70 }}
                                        />
                                        <Stack
                                            direction={"column"}
                                            spacing={-1}
                                            alignItems={"left"}
                                            justifyContent={"center"}
                                        >
                                            <h3>{user.first_name + " " + user.last_name}</h3>
                                            <p style={{ color: "black" }}>{user.role}</p>
                                            <p style={{ fontSize: 15, color: "grey" }}>{user.bio}</p>
                                        </Stack>
                                    </Stack>
                                </CardContent>

                                <CardActions>
                                    <Stack
                                        direction={"row"}
                                        spacing={2}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                    >
                                        <Tooltip title="Edit Profile" style={{ cursor: "pointer" }}>
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: "green" }}
                                                onClick={handleOpenInfoModal}
                                            >
                                                <EditNote style={{ marginRight: 2 }} /> Edit
                                            </Button>
                                        </Tooltip>
                                        <Tooltip
                                            title="Delete Profile"
                                            style={{ cursor: "pointer" }}
                                        >
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: "red" }}
                                                onClick={handleOpenDeleteModal}
                                            >
                                                <AutoDelete style={{ marginRight: 2 }} /> Delete{" "}
                                            </Button>
                                        </Tooltip>
                                    </Stack>
                                </CardActions>
                            </Stack>
                            <Divider />
                            <CardContent>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="basic tabs example"
                                    >
                                        <Tab
                                            label="Information"
                                            {...a11yProps(0)}
                                            sx={{ minWidth: "auto", width: "auto" }}
                                        />
                                        {(isPermission === "admin" || isPermission === "SME") && (
                                            <Tab
                                                label="Softwares"
                                                {...a11yProps(1)}
                                                sx={{ minWidth: "auto", width: "auto" }}
                                            />
                                        )}

                                        {isPermission === "admin" && (
                                            <Tab
                                                label="Managed Users"
                                                {...a11yProps(2)}
                                                sx={{ minWidth: "auto", width: "auto" }}
                                            />
                                        )}
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value} index={0}>
                                    <UserInformation user={user} />
                                </CustomTabPanel>
                                {(isPermission === "admin" || isPermission === "SME") && (
                                    <CustomTabPanel value={value} index={1}>
                                        <SoftwareGrid />
                                    </CustomTabPanel>
                                )}
                                {isPermission === "admin" && (
                                    <CustomTabPanel value={value} index={2}>
                                        <ManagedUsers />
                                    </CustomTabPanel>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                ) : (
                    <Card style={{ width: "80%", margin: "0 auto" }}>
                        <CardContent>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="left"
                                justifyContent="left"
                                sx={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Stack direction={"row"} spacing={2}>
                                    <Skeleton variant="circular" width={70} height={70} />
                                    <Stack
                                        direction="column"
                                        spacing={1}
                                        alignItems="left"
                                        justifyContent="center"
                                    >
                                        <Skeleton variant="text" width={150} height={30} />
                                        <Skeleton variant="text" width={100} height={20} />
                                        <Skeleton variant="text" width={200} height={20} />
                                    </Stack>
                                </Stack>
                                <CardActions>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Skeleton variant="rectangular" width={100} height={40} />
                                        <Skeleton variant="rectangular" width={100} height={40} />
                                    </Stack>
                                </CardActions>
                            </Stack>
                        </CardContent>

                        <Divider />
                        <CardContent>
                            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                                <Skeleton variant="rectangular" width={100} height={40} />
                                <Skeleton variant="rectangular" width={100} height={40} />
                                <Skeleton variant="rectangular" width={100} height={40} />
                            </Box>
                            <Skeleton variant="rectangular" width="100%" height={300} />
                        </CardContent>
                    </Card>
                )}
            </Container>
            {user ? (
                <>
                    <EditInformationModal
                        openModal={openInfoModal}
                        handleCloseModal={handleCloseInfoModal}
                        user={user}
                    />
                    <DeleteUserModal
                        openModal={openDeleteModal}
                        handleCloseModal={handleCloseDeleteModal}
                        username={user.username}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
