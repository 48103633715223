import { React, useEffect, useState } from "react";
import IosShareIcon from "@mui/icons-material/IosShare";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";

import { Button, Stack, Toolbar, Tooltip } from "@mui/material";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const SWOTSubHeaderComponent = ({ contents, software_id }) => {
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        axiosBaseURL
            .get("/auth/check-permission/")
            .then((response) => {
                console.log(response.data);
                if (
                    response.data["Groups"].includes("admin") ||
                    response.data["Groups"].includes("SME")
                ) {
                    setIsEdit(true);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);

    const handleExport = () => {
        const input = document.getElementById("software-overview");
        if (input) {
            const pdf = new jsPDF("p", "px", "a4"); // Letter size page of PDF
            const contentWidth = input.clientWidth;
            const contentHeight = input.clientHeight;
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            const marginLeft = 20; // Left margin
            const marginRight = 20; // Right margin
            const marginTop = 20; // Top margin

            const scale = (pageWidth - marginLeft - marginRight) / contentWidth; // Scale factor to fit content to page

            html2canvas(input, { scale: 2 }) // Increase resolution by scaling
                .then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");
                    let pdfPageHeight = 0;
                    let y = 0;

                    const marginBottom = 20; // Bottom margin

                    while (y < contentHeight) {
                        pdf.addImage(
                            imgData,
                            "PNG",
                            marginLeft,
                            pdfPageHeight + marginTop,
                            contentWidth * scale,
                            pageHeight - marginTop - marginBottom
                        );
                        y += pageHeight - marginTop - marginBottom;
                        pdfPageHeight += pageHeight - marginTop - marginBottom;

                        // Add new page only if there's more content to add
                        if (y < contentHeight) {
                            pdf.addPage();
                        }
                    }

                    pdf.save("report.pdf");
                });
        }
    };

    const handleEdit = () => {
        navigate("/account/addSoftware/" + software_id);
    };

    const handlePrint = () => {
        const input = document.getElementById("software-overview");
        if (input) {
            const content = input.innerHTML;
            const printWindow = window.open("", "_blank");
            printWindow.document.open();
            printWindow.document.write(`
                <html>
                    <head>
                        <title>Print SWOT</title>
                    </head>
                    <body>
                        <div id="software-overview" class="background-color">
                            ${content}
                        </div>
                    </body>
                </html>
            `);
            printWindow.document.close();
            printWindow.print();
            window.close();
        }
    };

    const handleLink = () => {
        console.log("Linking SWOT");
    };

    const handleDelete = async () => {
        console.log("Deleting SWOT");
        if (!window.confirm("Are you sure?")) {
            return;
        }
        try {
            await axiosBaseURL
                .post(`/apiv1/delete_software/${software_id}/`)
                .then((response) => {
                    console.log("API response:", response.data);
                    navigate("/account/manageSoftware/");
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } catch (error) {
            // Handle errors if the request fails
            console.error("Error:", error);
        }
    };

    const handleShare = () => {
        console.log("Sharing SWOT");
    };

    const buttonStyle = {
        color: "grey",
        borderRadius: "5px",
        marginLeft: "2px",
        marginRight: "2px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "blue",
        },
    };

    return (
        <Stack
            direction="row"
        >
            <Tooltip
                title={isEdit ? "Edit" : "Requires permissions to edit"}
                placement="bottom"
            >
                <span>
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={isEdit ? handleEdit : null}
                        style={{
                            cursor: isEdit ? "pointer" : "not-allowed",
                            opacity: isEdit ? 1 : 0.5,
                            ...buttonStyle,
                        }}
                        disabled={!isEdit}
                    >
                        Edit
                    </Button>
                </span>
            </Tooltip>
            <Tooltip
                title={isEdit ? "Delete" : "Requires permissions to delete"}
                placement="bottom"
            >
                <span>
                    <Button
                        variant="text"
                        color="secondary"
                        size="small"
                        onClick={isEdit ? handleDelete : null}
                        style={{
                            cursor: isEdit ? "pointer" : "not-allowed",
                            opacity: isEdit ? 1 : 0.5,
                            ...buttonStyle,
                        }}
                        disabled={!isEdit}
                    >
                        Delete
                    </Button>
                </span>
            </Tooltip>
        </Stack>
    );
};

export default SWOTSubHeaderComponent;
