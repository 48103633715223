import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axiosBaseURL from "../../../httpCommon";
import {
  Modal,
  FormControl,
  TextField,
  Button,
  Stack,
  Chip,
  Input,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  Tooltip,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Close, ContentCopy } from "@mui/icons-material";
// import { makeStyles } from "@mui/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.3)", // Increased blur radius for a more blurry shadow
  p: 4,
};

function EditUserModal({
  openModal,
  handleCloseModal,
  user,
  handleChange,
  handleSubmit,
  userInfo,
  handleOpenPasswordModal,
  isPreventSubmit,
  initialUserInfo,
}) {
  // const classes = useStyles();
  const [values, setValues] = useState(["test"]);
  const [currValue, setCurrValue] = useState("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const [selectedRole, setSelectedRole] = useState(userInfo['permission_groups']);
  const [fetchedRole, setFetchedRole] = useState(userInfo['permission_groups']);
  console.log(selectedRole);
  console.log(fetchedRole);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.name);
  };

  const handleKeyUp = (e) => {
    console.log(e.keyCode);
    if (e.keyCode === 32) {
      setValues((oldState) => [...oldState, e.target.value]);
      setCurrValue("");
    }
  };

  useEffect(() => {
    console.log(values);
  }, [values]);

  const handleChangeValue = (e) => {
    setCurrValue(e.target.value);
  };

  const handleDeleteValue = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    console.log(item);
    setValues(arr);
  };

  const handleClick = () => {
    openSnackbar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleCopyToClipboard = (text) => () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        setSnackbarMessage('Copied to clipboard')
        handleClick();
      }).catch((err) => {
        console.error('Failed to copy: ', err);
      });
    } else {
      console.error('Clipboard API not supported');
    }
  };

  const snackBar = () => {
    return (
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
      />
    );
  };

  const handleUpdateRole = async (event) => {
    event.preventDefault();
    await axiosBaseURL.post("/auth/admin-update-role/", {
      username: userInfo["username"],
      role: selectedRole,
      cur_role: fetchedRole,
    }).then((response) => {
      console.log(response);
      setFetchedRole(selectedRole);
    }).catch((error) => {
      console.error(error);
    });
  }
  const handleResetPermission = () => {
    setSelectedRole(fetchedRole);
  };

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          User Information
        </Typography>
        <Divider sx={{ my: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <b>Username</b>
            <Typography variant="body1">{userInfo["username"]}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <b>Name</b>
            <Typography variant="body1">
              {userInfo["first_name"]} {userInfo["last_name"]}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <b>Phone #</b>
            <Typography variant="body1">
              {userInfo["phone"]}{" "}
              <Tooltip title="copy to clipboard" style={{ cursor: "pointer" }}>
                <ContentCopy
                  fontSize="small"
                  onClick={handleCopyToClipboard(userInfo["phone"])}
                />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <b>Email</b>
            <Typography variant="body1">{userInfo["email"]}{" "}
              <Tooltip title="copy to clipboard" style={{ cursor: "pointer" }}>
                <ContentCopy
                  fontSize="small"
                  onClick={handleCopyToClipboard(userInfo["phone"])}
                />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <b>Role</b>
            <Typography variant="body1">{userInfo["role"]}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <b>Bio</b>
            <Typography variant="body1">{userInfo["bio"]}</Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 2,
            padding: 2,
            borderRadius: 5,
            backgroundColor: "#F8FBFE",
          }}
        >
          <form onSubmit={handleUpdateRole}>
            <Typography variant="p">
              <b>Permissions</b>
            </Typography>
            <Stack direction="row" spacing={2}>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRole === "user"}
                      onChange={handleRoleChange}
                      name="user"
                    />
                  }
                  label="User"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRole === "SME"}
                      onChange={handleRoleChange}
                      name="SME"
                    />
                  }
                  label="Subject Matter Expert"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRole === "admin"}
                      onChange={handleRoleChange}
                      name="admin"
                    />
                  }
                  label="Admin"
                />
              </FormGroup>

            </Stack>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="left"
              alignItems={"left"}
              sx={{ marginTop: 2 }}
            >
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
                sx={{ mt: 2, width: "15%" }}
                disabled={
                  JSON.stringify(fetchedRole) === JSON.stringify(selectedRole)
                }
              >
                Update
              </Button>
              <Button
                onClick={handleResetPermission}
                variant="contained"
                color="error"
                size="small"
                sx={{ mt: 2, width: "15%" }}
                disabled={
                  JSON.stringify(fetchedRole) === JSON.stringify(selectedRole)
                }
              >
                Reset
              </Button>
            </Stack>
          </form>
        </Box>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="right"
          alignItems={"right"}
          sx={{ marginTop: 2 }}
        >
          {/* <Button
            type="submit"
            variant="contained"
            color="error"
          >
            Delete User
          </Button> */}
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color="primary"
          // sx={{ mt: 2, width: "15%" }}
          >
            Close
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default function ManagedUsers() {
  const [users, setUsers] = React.useState([]);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState({});
  const [initialUserInfo, setInitialUserInfo] = React.useState({});
  const [isPreventSubmit, setIsPreventSubmit] = React.useState(false);

  const handleOpenEditModal = (user) => {
    setSelectedUser(user);
    setUserInfo(user);
    setInitialUserInfo(user);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedUser(null);
  };

  const handleChange = (field, value) => {
    setUserInfo((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  const handleOpenPasswordModal = () => {
    // Handle opening password modal logic here
  };

  const columns = [
    {
      field: "username",
      headerName: "Username",
      width: 120,
    },
    {
      field: "name",
      headerName: "Name",
      width: 130,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
    },
    {
      field: "role",
      headerName: "Role",
      width: 120,
    },
    {
      field: "permission_groups",
      headerName: "User Permission",
      width: 100,
    },
  ];

  const handleUsersFetch = async () => {
    try {
      const response = await axiosBaseURL.get("/auth/get-user-excerpt/");
      const users = response.data.message.map((user, index) => ({
        id: index + 1, // Add an id field for DataGrid
        username: user.user__username,
        name: `${user.user__first_name} ${user.user__last_name}`,
        first_name: user.user__first_name,
        last_name: user.user__last_name,
        email: user.user__email,
        phone: user.phone,
        company_info: user.company_info,
        industry: user.industry,
        role: user.role,
        bio: user.bio,
        permission_groups:
          user.user_permission_groups &&
            user.user_permission_groups.trim() !== ""
            ? user.user_permission_groups
            : "",
      }));
      setUsers(users);
      console.log(users);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleUsersFetch();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "auto", // Ensure the scrollbar is visible
      }}
    >
      <DataGrid
        rows={users}
        columns={columns}
        density="compact" // Ensure consistent density
        onRowDoubleClick={(params) => handleOpenEditModal(params.row)}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 30]}
      />
      {selectedUser && (
        <EditUserModal
          openModal={openEditModal}
          handleCloseModal={handleCloseEditModal}
          user={selectedUser}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          userInfo={userInfo}
          handleOpenPasswordModal={handleOpenPasswordModal}
          isPreventSubmit={isPreventSubmit}
          initialUserInfo={initialUserInfo}
        />
      )}
    </Box>
  );
}
