import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Alert, Divider, FormControl, Stack, TextField, Tooltip } from "@mui/material";
import axiosBaseURL from "../../../httpCommon";
import { EditNote, Password } from "@mui/icons-material";
import PasswordUpdateModal from "./PasswordUpdateModal";
import AlertModal from "../../Globals/AlertModal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.3)", // Increased blur radius for a more blurry shadow
    p: 4,
};

export default function EditInformationModal({
    openModal,
    handleCloseModal,
    user,
}) {
    const [initialUserInfo, setInitialUserInfo] = React.useState({
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        bio: "",
        role: "",
        permission_groups: [],
    });

    const [userInfo, setUserInfo] = React.useState(initialUserInfo);
    const [isPreventSubmit, setIsPreventSubmit] = React.useState(true);

    const [isPreventClosePasswordModal, setIsPreventClosePasswordModal] = React.useState(true);
    const [openPasswordModal, setOpenPasswordModal] = React.useState(false);
    const handleOpenPasswordModal = () => setOpenPasswordModal(true);
    const handleClosePasswordModal = (prevent) => {
        if (isPreventClosePasswordModal) {
            if (!window.confirm("Are you sure?")) {
                return;
            }
        }
        setOpenPasswordModal(false);
    };

    const [openAlertModal, setOpenAlertModal] = React.useState(false);
    const handleOpenAlertModal = () => setOpenAlertModal(true);
    const handleCloseAlertModal = () => setOpenAlertModal(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertStatus, setAlertStatus] = React.useState("");

    React.useEffect(() => {
        if (user) {
            const userInfo = {
                username: user.username,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                bio: user.bio,
                role: user.role,
            };
            setUserInfo(userInfo);
            setInitialUserInfo(userInfo);
        }
    }, [user]);

    const handleChange = async (name, value) => {
        let validationMessage = "";
        let validationColor = "";

        if (name === "email") {
            const emailValidation = await isValidEmail(value);
            validationMessage = emailValidation.message;
            validationColor = emailValidation.color;
        } else if (name === "phone") {
            validationMessage = isValidPhoneNumber(value)
                ? "Looks good!"
                : "Invalid phone number format";
            validationColor = isValidPhoneNumber(value) ? "green" : "red";
        } else if (name === "username") {
            const usernameValidation = await isUserNameAvailable(value);
            validationMessage = usernameValidation.message;
            validationColor = usernameValidation.color;
        }

        setUserInfo((prevState) => ({
            ...prevState,
            [name]: value,
            [`${name}ValidationMessage`]: validationMessage,
            [`${name}ValidationColor`]: validationColor,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(userInfo);
        try {
            const response = await axiosBaseURL.post("/auth/update-user/", userInfo);
            console.log(response.data.message);
            setAlertMessage("User information Updated Successfully!!");
            setAlertStatus("success");
            setIsPreventSubmit(false);
            handleCloseModal();
            handleOpenAlertModal();

        } catch (error) {
            setAlertMessage(error);
            setAlertStatus("error");
            handleOpenAlertModal();
            console.error("Error updating user:", error);
        }
    };

    const isValidEmail = async (email) => {
        // Check if the email is not modified or is the same as the initial value
        if (email === initialUserInfo["email"]) {
            return { message: "", color: "" };
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the email matches the pattern
        if (!emailPattern.test(email)) {
            setIsPreventSubmit(true);
            return { message: "Invalid email format.", color: "red" };
        }

        try {
            const response = await axiosBaseURL.post("/auth/check-email/", { email });
            if (response.data.doesExist === false) {
                setIsPreventSubmit(false);
                return { message: "Email is available!", color: "green" };
            } else {
                setIsPreventSubmit(true);
                return { message: "Email is already taken.", color: "red" };
            }
        } catch (error) {
            console.error("Error checking email availability:", error);
            return { message: "Error checking email availability.", color: "red" };
        }
    };

    const isUserNameAvailable = async (username) => {
        if (username === initialUserInfo["username"]) {
            return { message: "", color: "" };
        }
        try {
            const response = await axiosBaseURL.post("/auth/check-username/", {
                username,
            });
            if (response.data.doesExist === false) {
                setIsPreventSubmit(false);
                return { message: "Username is available!", color: "green" };
            } else {
                setIsPreventSubmit(true);
                return { message: "Username is already taken.", color: "red" };
            }
        } catch (error) {
            console.error("Error checking username availability:", error);
            return { message: "Error checking username availability.", color: "red" };
        }
    };

    const isValidPhoneNumber = (phone) => {
        const phonePattern = /^\+?[1-9]\d{1,14}$/; // E.164 international phone number format
        setIsPreventSubmit(!phonePattern.test(phone));
        return phonePattern.test(phone);
    };

    return (
        <div>
            <AlertModal openModal={openAlertModal} handleCloseModal={handleCloseAlertModal} status={alertStatus} message={alertMessage} />
            <PasswordUpdateModal
                openModal={openPasswordModal}
                handleCloseModal={handleClosePasswordModal}
                setIsPreventClose={setIsPreventClosePasswordModal}
            />
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        Update Information
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                            <FormControl fullWidth sx={{ flex: "1 1 45%" }} margin="dense">
                                <TextField
                                    id="username"
                                    name="username"
                                    label="Username"
                                    variant="outlined"
                                    size="small"
                                    value={userInfo["username"]}
                                    onChange={(e) => handleChange("username", e.target.value)}
                                    helperText={userInfo["usernameValidationMessage"]}
                                    FormHelperTextProps={{
                                        style: {
                                            color: userInfo["usernameValidationColor"] || "inherit",
                                        },
                                    }}
                                />
                            </FormControl>
                            <Tooltip
                                title="Edit Current Password"
                                style={{ cursor: "pointer" }}
                            >
                                <Button
                                    // variant="outlined"
                                    style={{ color: "red" }}
                                    size="small"
                                    onClick={handleOpenPasswordModal}
                                >
                                    <EditNote style={{ marginRight: 2 }} /> Update Password
                                </Button>
                            </Tooltip>
                            <FormControl fullWidth sx={{ flex: "1 1 45%" }} margin="dense">
                                <TextField
                                    id="first_name"
                                    name="first_name"
                                    label="First Name"
                                    variant="outlined"
                                    size="small"
                                    value={userInfo["first_name"]}
                                    onChange={(e) => handleChange("first_name", e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ flex: "1 1 45%" }} margin="dense">
                                <TextField
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    variant="outlined"
                                    size="small"
                                    value={userInfo["last_name"]}
                                    onChange={(e) => handleChange("last_name", e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ flex: "1 1 45%" }} margin="dense">
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    size="small"
                                    value={userInfo["email"]}
                                    onChange={(e) => handleChange("email", e.target.value)}
                                    helperText={userInfo["emailValidationMessage"]}
                                    FormHelperTextProps={{
                                        style: {
                                            color: userInfo["emailValidationColor"] || "inherit",
                                        },
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ flex: "1 1 45%" }} margin="dense">
                                <TextField
                                    id="phone"
                                    name="phone"
                                    label="Phone"
                                    type="tel"
                                    variant="outlined"
                                    size="small"
                                    value={userInfo["phone"]}
                                    onChange={(e) => handleChange("phone", e.target.value)}
                                    helperText={userInfo["phoneValidationMessage"]}
                                    FormHelperTextProps={{
                                        style: {
                                            color: userInfo["phoneValidationColor"] || "inherit",
                                        },
                                    }}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ flex: "1 1 45%" }} margin="dense">
                                <TextField
                                    multiline
                                    id="bio"
                                    name="bio"
                                    label="Bio"
                                    variant="outlined"
                                    size="small"
                                    value={userInfo["bio"]}
                                    onChange={(e) => handleChange("bio", e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ flex: "1 1 45%" }} margin="dense">
                                <TextField
                                    multiline
                                    id="role"
                                    name="role"
                                    label="Role"
                                    variant="outlined"
                                    size="small"
                                    value={userInfo["role"]}
                                    onChange={(e) => handleChange("role", e.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="right"
                            alignItems={"right"}
                            sx={{ marginTop: 2 }}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, width: "15%" }}
                                disabled={
                                    isPreventSubmit ||
                                    JSON.stringify(initialUserInfo) === JSON.stringify(userInfo)
                                }
                            >
                                Update
                            </Button>
                            <Button
                                onClick={handleCloseModal}
                                variant="contained"
                                color="error"
                                sx={{ mt: 2, width: "15%" }}
                            >
                                Discard
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}