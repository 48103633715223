import { Stack } from '@mui/material';
import React from 'react';

const UserInformation = ({user}) => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
            <h1 style={{ borderBottom: '2px solid #ddd', paddingBottom: '10px' }}>Information</h1>
            <Stack direction="row" spacing={2} alignItems="left" justifyContent="left">
            <div><p><strong>Name:</strong> {user.first_name} {user.last_name}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Username:</strong> {user.username}</p>
            <p><strong>Phone:</strong> {user.phone}</p></div>
            <div><p><strong>Role:</strong> {user.role}</p>
            <p><strong>Company:</strong> {user.company_info}</p>
            <p><strong>Industry:</strong> {user.industry}</p>
            <p><strong>Bio:</strong> {user.bio}</p></div>
            </Stack>
        </div>
    );
};

export default UserInformation;