import React from "react";
import TextField from "@mui/material/TextField";
import Done from "@mui/icons-material/Done";
import Cancel from "@mui/icons-material/Cancel";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Rating, Tooltip } from "@mui/material";
import { PlaylistAdd } from "@mui/icons-material";
import AddFeatureModel from "./AddFeatureModel.js";

function FeaturesComponent({
    key,
    feature,
    pricings,
    handleSave,
    handleDelete,
    handleChange,
    all_features,
    set_all_features,
    categories,
}) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    //For Add New Feature Modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Stack
                direction={isSmallScreen ? "column" : "row"}
                spacing={2}
                sx={{ marginBottom: 2 }}
            >
                <TextField
                    name="feature_map_id"
                    id="feature_map_id"
                    variant="outlined"
                    helperText="Feature"
                    value={feature.feature_map_id}
                    onChange={(e) => handleChange("feature_map_id", e.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                    select
                    fullWidth
                >
                    <option value="" disabled>
                        Select a feature
                    </option>
                    {all_features.map((option) => (
                        <option key={option.map_id} value={option.map_id}>
                            {option.name}
                        </option>
                    ))}
                </TextField>
                <Tooltip title="Add new feature" placement="bottom">
                    <PlaylistAdd
                        onClick={() => handleOpen()}
                        sx={{ fontSize: "30px", cursor: "pointer" }}
                    />
                </Tooltip>
                <TextField
                    label="Benefits"
                    name="benefits"
                    id="benefits"
                    value={feature.benefits}
                    onChange={(e) => handleChange("benefits", e.target.value)}
                    variant="outlined"
                    sx={{
                        margin: "auto",
                        marginTop: { xs: "15px", sm: "auto" },
                        marginBottom: { xs: "15px", sm: "auto" },
                    }}
                    fullWidth
                    multiline
                />
                <TextField
                    helperText="Pricing Type"
                    name="pricing"
                    id="pricing"
                    defaultValue={feature.title ? feature.title : ""}
                    // defaultValue={feature.title}
                    onChange={(e) => handleChange("pricing", e.target.value)}
                    variant="outlined"
                    sx={{ margin: "auto" }}
                    select
                    SelectProps={{
                        native: true,
                    }}
                    fullWidth
                >
                    <option value="" disabled>
                        Select
                    </option>
                    {pricings.map((element) => (
                        <option
                            key={element.id}
                            value={element.id != 0 ? element.id : element.title}
                        >
                            {element.title}
                        </option>
                    ))}
                </TextField>
                <Box
                    sx={{
                        "& > legend": { mt: 2 },
                    }}
                >
                    <Rating
                        name="simple-controlled"
                        size="small"
                        value={feature.rating}
                        onChange={(e) => handleChange("rating", parseFloat(e.target.value))}
                    />
                </Box>
                <Stack direction="column">
                    {!feature.isSaved && (
                        <>
                            <Tooltip title="Save" placement="right-end">
                                <Done className="doneIcon" onClick={handleSave} />
                            </Tooltip>
                            <Divider orientation="horizontal" flexItem sx={{ my: 0.5 }} />
                        </>
                    )}
                    <Tooltip title="Delete" placement="right-end">
                        <Cancel className="cancelIcon" onClick={handleDelete} />
                    </Tooltip>
                </Stack>
            </Stack>
            <AddFeatureModel
                open={open}
                categories={categories}
                set_all_features={set_all_features}
                setOpen={setOpen}
                handleClose={handleClose}
                handleOpen={handleOpen}
            />
        </>
    );
}

export default FeaturesComponent;
