import React, { useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import axiosBaseURL from "../../httpCommon";
import { FormControl, TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const LoginScreen = () => {
    const location = useLocation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [resError, setResError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    const handleRegister = () => {
        navigate("/register");
    };

    const handleLoginDjango = async (username, password) => {
        try {
            const response = await axiosBaseURL.post("/auth/login/", {
                username,
                password,
            });
            console.log("API response:", response.data);
            localStorage.setItem("token", response.data.user.token);
            window.location.href = "/";
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage("Incorrect email or password. Please try again.");
        }
    };
    const publicUrl =
        process.env.REACT_APP_IS_DEPLOYMENT == "true"
            ? process.env.REACT_APP_PUBLIC_URL
            : process.env.REACT_APP_PUBLIC_URL_TEST;

    const backgroundImageUrl = `${publicUrl}/images/login_bg.jpg`;

    return (
        <>
            <div
                className="main-container"
                style={{ backgroundImage: `url(${backgroundImageUrl})` }}
            >
                <div className="parent-container">
                    <Stack
                        direction="column"
                        spacing={2}
                        justifyContent="left"
                        alignItems="left"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "50px",
                            width: "50vh",
                        }}
                    >
                        <h1>ChatAlloy</h1>
                        <Stack direction="column" spacing={1} alignItems={"left"}>
                            <h3>Log in</h3>
                            <p>Start your manufacturing journey with ChatAlloy.</p>
                        </Stack>
                        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
                        <FormControl className="form-control" fullWidth margin="normal">
                            <TextField
                                id="username"
                                label="Username"
                                variant="outlined"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </FormControl>
                        <FormControl className="form-control" fullWidth margin="normal">
                            <TextField
                                id="password-input"
                                label="Password"
                                variant="outlined"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormControl>
                        <Stack
                            direction={"column"}
                            spacing={3}
                            sx={{ justifyContent: "space-between", width: "100%" }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleLoginDjango(username, password)}
                                fullWidth
                            >
                                Log in
                            </Button>
                            <Typography variant="body2">
                                Don't have a ChatAlloy account?{" "}
                                <span
                                    onClick={handleRegister}
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    Register now
                                </span>
                            </Typography>
                        </Stack>
                    </Stack>
                </div>
            </div>
        </>
    );
};

export default LoginScreen;
