import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CheckCircle, Error } from "@mui/icons-material";
import { Stack } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    borderRadius: 3,
    boxShadow: 24,
    p: 4,
};

export default function AlertModal({
    openModal,
    handleCloseModal,
    status,
    message,
}) {
    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center", // Ensure text is centered
                        }}
                    >
                        {status === "success" ? (
                            <CheckCircle color="success" sx={{ fontSize: "100px" }} />
                        ) : (
                            <Error color="error" sx={{ fontSize: "100px" }} />
                        )}
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, textAlign: "center" }}
                        >
                            {message}
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </Modal>
    );
}
