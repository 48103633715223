import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosBaseURL from "../httpCommon";
import {
    Typography,
    Card,
    CardContent,
    Box,
    Container,
    Stack,
    Tooltip,
} from "@mui/material";
import SWOTSubHeaderComponent from "./Components/SWOTSubHeaderComponent";
import { Help } from "@mui/icons-material";

export default function SWOTScreen() {
    const [software, setSoftware] = React.useState(null);
    const publicUrl =
        process.env.REACT_APP_IS_DEPLOYMENT == "true"
            ? process.env.REACT_APP_PUBLIC_URL
            : process.env.REACT_APP_PUBLIC_URL_TEST;

    const { id } = useParams();

    useEffect(() => {
        const fetchSoftwareOverviewData = async (id) => {
            try {
                const response = await axiosBaseURL
                    .get(`/apiv1/get_software_overview/${id}`)
                    .then((response) => {
                        setSoftware(response.data["software product"]);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            } catch (error) {
                // Handle errors if the request fails
                console.error("Error:", error);
            }
        };
        fetchSoftwareOverviewData(id);
    }, [id]);

    const [softwareOverviewHTML, setSoftwareOverviewHTML] = React.useState("");

    React.useEffect(() => {
        const observer = new MutationObserver((mutationsList, observer) => {
            // Look through all mutations that just occured
            for (let mutation of mutationsList) {
                // If the addedNodes property has one or more nodes
                if (mutation.addedNodes.length) {
                    const softwareOverview = document.getElementById("software-overview");
                    if (softwareOverview) {
                        setSoftwareOverviewHTML(softwareOverview.innerHTML);
                        observer.disconnect(); // Stop observing once we've found the element
                    }
                }
            }
        });

        // Start observing the document with the configured parameters
        observer.observe(document, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, []);

    return (
        <Box marginTop={2} marginBottom={2}>
            <Container maxWidth="fixed">
                <div
                    style={{
                        backgroundColor: "white",
                        minHeight: "86vh",
                        borderRadius: "5px",
                        padding: "20px",
                        maxWidth: "1000px",
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}
                >
                    {software ? (
                        <div>
                            {/* TODO: Add the Edit / Delete / Share / Export / Print / Copy Link Bottons*/}

                            <div
                                id="software-overview"
                                style={{
                                    marginLeft: "15%",
                                    marginRight: "15%",
                                    marginTop: "2%",
                                }}
                            >
                                <Stack spacing={2} direction="row">
                                    <div
                                        style={{
                                            height: "100px",
                                            minWidth: "100px",
                                            backgroundImage: `url(${publicUrl}/${software.logo})`,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "center",
                                            border: "1px solid #D9D9D9",
                                            borderRadius: "5px",
                                            marginBottom: "20px",
                                            padding: "2px",
                                        }}
                                    />
                                    <Stack
                                        // spacing={2}
                                        direction="column"
                                    >
                                        <Typography
                                            variant="h4"
                                            sx={{ fontSize: { xs: "18px", sm: "3rem" } }}
                                            style={{ marginBottom: 4 }}
                                        >
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: software.prodName,
                                                }}
                                            />
                                        </Typography>
                                        <SWOTSubHeaderComponent
                                            contents={softwareOverviewHTML}
                                            software_id={id}
                                        />
                                    </Stack>
                                </Stack>

                                <Typography
                                    variant="h6"
                                    style={{
                                        marginBottom: 4,
                                        marginTop: 10,
                                        fontWeight: "bold",
                                    }}
                                >
                                    Description
                                </Typography>
                                <Typography variant="body1">{software.description}</Typography>
                                <ul
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: 20,
                                    }}
                                >
                                    <li>
                                        <Typography variant="body1">
                                            <b>Type:</b> {software.prodType}
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            <b>Year Founded:</b> {software.date}
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            <b>LinkedIn:</b>
                                            <a href={software.linkedin}> {software.prodName}</a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            <b>Website:</b>
                                            <a href={software.website}> {software.website}</a>
                                        </Typography>
                                    </li>
                                </ul>

                                <div>
                                    <Typography
                                        variant="h5"
                                        style={{ marginBottom: 4, marginTop: 10 }}
                                    >
                                        🔑 Key Software Features
                                    </Typography>
                                    <Stack spacing={2} direction="column">
                                        {Object.entries(
                                            software.features.reduce((acc, feature) => {
                                                if (!acc[feature.title]) {
                                                    acc[feature.title] = [];
                                                }
                                                acc[feature.title].push(feature);
                                                return acc;
                                            }, {})
                                        ).map(([title, features], index) => (
                                            <div key={index}>
                                                <Card
                                                    style={{
                                                        backgroundColor: "#F8FBFE",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <Typography variant="h6">{title}</Typography>
                                                    <ul
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginTop: 20,
                                                        }}
                                                    >
                                                        {features.map((feature, index) => (
                                                            <li>
                                                                <Typography variant="body1">
                                                                    <Stack direction="row">
                                                                        <>{feature.featName}</>
                                                                        <Tooltip
                                                                            title={feature.benefits}
                                                                            placement="right"
                                                                        >
                                                                            <Help
                                                                                sx={{
                                                                                    marginLeft: "5px",
                                                                                    fontSize: 12,
                                                                                    color: "grey",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                            />
                                                                        </Tooltip>
                                                                    </Stack>
                                                                </Typography>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Card>
                                            </div>
                                        ))}
                                    </Stack>
                                </div>

                                <Typography
                                    variant="h5"
                                    style={{ marginBottom: 4, marginTop: 20 }}
                                >
                                    💰 Pricing
                                </Typography>
                                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                                    {software.pricing.map((price, index) => (
                                        <Box m={2} key={index}>
                                            <Card>
                                                <CardContent>
                                                    <Typography variant="h6">
                                                        <b>{price.title}</b>
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        Sign Up Fee: ${price.signUp}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        Subscription Cost: ${price.subscription}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        Price Frequency: {price.frequency}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        Users: {price.user}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    ))}
                                </Stack>
                                <Typography
                                    variant="h5"
                                    style={{ marginBottom: 4, marginTop: 20 }}
                                >
                                    <Tooltip title="Strength, Weakness, Opportunity, Threats">
                                        ➕ S.W.O.T.
                                    </Tooltip>
                                </Typography>
                                <Stack spacing={2} direction="column">
                                    <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                                        <Card
                                            style={{
                                                minWidth: "50%",
                                                backgroundColor: "#BED9DE",
                                            }}
                                        >
                                            <CardContent>
                                                <Typography variant="h6">Strength</Typography>
                                                {software.strength}
                                            </CardContent>
                                        </Card>
                                        <Card
                                            style={{
                                                minWidth: "50%",
                                                backgroundColor: "#FDD1AA",
                                            }}
                                        >
                                            <CardContent>
                                                <Typography variant="h6">Weakness</Typography>
                                                {software.weakness}
                                            </CardContent>
                                        </Card>
                                    </Stack>
                                    <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                                        <Card
                                            style={{
                                                minWidth: "50%",
                                                backgroundColor: "#D9F2D0",
                                            }}
                                        >
                                            <CardContent>
                                                <Typography variant="h6">Opportunities</Typography>
                                                {software.opportunities}
                                            </CardContent>
                                        </Card>
                                        <Card
                                            style={{
                                                minWidth: "50%",
                                                backgroundColor: "#FBCAC0",
                                            }}
                                        >
                                            <CardContent>
                                                <Typography variant="h6">Threats</Typography>
                                                {software.threats}
                                            </CardContent>
                                        </Card>
                                    </Stack>
                                </Stack>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                height: "70vh",
                            }}
                        >
                            <img
                                src={`${publicUrl}/loader.webp`}
                                alt="loader"
                                style={{ height: "50px", width: "auto" }}
                            />
                        </div>
                    )}
                </div>
            </Container>
        </Box>
    );
}
