import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axiosBaseURL from "../../../httpCommon";
import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';

const publicUrl =
  process.env.REACT_APP_IS_DEPLOYMENT === "true"
    ? process.env.REACT_APP_PUBLIC_URL
    : process.env.REACT_APP_PUBLIC_URL_TEST;

const columns = [
  {
    field: 'logo', headerName: 'Logo', width: 150, renderCell: (params) => (
      <img src={publicUrl + "/" + params.value} alt="logo" style={{ width: '50%' }} />
    )
  },
  { field: 'name', headerName: 'Name', width: 150 },
  { field: 'type', headerName: 'Type', width: 150 },
  { field: 'author_name', headerName: 'Author Name', width: 200 },
  { field: 'created_at', headerName: 'Created At', width: 180 },
  { field: 'updated_at', headerName: 'Last Updated', width: 180 },
];

const SoftwareGrid = () => {
  const [sw_data, setSw_data] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosBaseURL.get("/apiv1/get_sw_users/");
        console.log("API Response:", response.data);
        setSw_data(response.data.message);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleOpenSoftware = (id) => {
    window.open("/account/manageSoftware/swot/" + id, "_blank");
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      {loading ? (
        <div>
          <div style={{ display: 'flex', marginBottom: 10 }}>
            {columns.map((column, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={column.width}
                height={40}
                style={{
                  marginRight: index < columns.length - 1 ? 10 : 0,
                  borderRadius: 5
                }}
              />
            ))}
          </div>
          {[...Array(5)].map((_, rowIndex) => (
            <div key={rowIndex} style={{ display: 'flex', marginBottom: 10 }}>
              {columns.map((column, colIndex) => (
                <Skeleton
                  key={colIndex}
                  variant="rectangular"
                  width={column.width}
                  height={40}
                  style={{
                    marginRight: colIndex < columns.length - 1 ? 10 : 0,
                    borderRadius: 5
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      ) : sw_data.length === 0 ? (
        <div>No Data Found</div>
      ) : (
        <DataGrid
          rows={sw_data}
          columns={columns}
          pageSize={5}
          onRowClick={(row) => {
            handleOpenSoftware(row.id);
          }}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.software_id}
          getRowClassName={() => 'pointer-cursor'}
        />
      )}
    </div>
  );
};

export default SoftwareGrid;