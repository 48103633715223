import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Alert, Divider, FormControl, Stack, TextField } from "@mui/material";
import AlertModal from "../../Globals/AlertModal";
import axiosBaseURL from "../../../httpCommon";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: '15px',
    boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.3)', // Increased blur radius for a more blurry shadow
    p: 4,
};

export default function DeleteUserModal({ openModal, handleCloseModal, username }) {
    console.log("username:", username);
    const [inputUsername, setInputUsername] = React.useState("");
    const [openAlertModal, setOpenAlertModal] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertStatus, setAlertStatus] = React.useState("");
    const [isPreventSubmit, setIsPreventSubmit] = React.useState(true);

    const handleOpenAlertModal = () => setOpenAlertModal(true);
    const handleCloseAlertModal = () => setOpenAlertModal(false);

    const handleChange = (event) => {
        const { value } = event.target;
        setInputUsername(value);
        setIsPreventSubmit(value !== username);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axiosBaseURL.delete(`/auth/delete-user/`);
            console.log("API response:", response.data);
            setAlertMessage("User was deleted successfully.");
            setAlertStatus("success");
            handleCloseModal();
            handleOpenAlertModal();
        } catch (error) {
            console.error("Error:", error);
            setAlertMessage("There was an error deleting the user. Please try again.");
            setAlertStatus("error");
            handleOpenAlertModal();
        }
    };

    return (
        <div>
            <AlertModal openModal={openAlertModal} handleCloseModal={handleCloseAlertModal} status={alertStatus} message={alertMessage} />
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h5">
                        Confirm User Deletion
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Please enter the user ID <strong>{username}</strong> to confirm deletion.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            <FormControl fullWidth sx={{ flex: '1 1 100%' }} margin="dense">
                                <TextField
                                    id="input_user_id"
                                    name="input_user_id"
                                    label="User ID"
                                    variant="outlined"
                                    size="small"
                                    value={inputUsername}
                                    onChange={handleChange}
                                    helperText={isPreventSubmit ? "User ID does not match." : "User ID matches."}
                                    FormHelperTextProps={{ style: { color: isPreventSubmit ? 'red' : 'green' } }}
                                />
                            </FormControl>
                        </Box>
                        <Stack direction="row" spacing={2} justifyContent="right" alignItems={"right"} sx={{ marginTop: 2 }}>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                sx={{ mt: 2 }}
                                disabled={isPreventSubmit}
                            >
                                Delete
                            </Button>
                            <Button onClick={handleCloseModal} variant="contained" color="error" sx={{ mt: 2, padding: 1 }}>
                                Cancel
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}